import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { Types } from './actions';

export const INITIAL_STATE = {
    needsQa: false,
    isQaMode: false,
    isSavingQa: false,
    isErrorSavingQa: false,
    qaDisplayValues: [],
    qaRunOnSave: []
};

export const setNeedQa = (state = INITIAL_STATE, action) => {
    const { needsQa } = action;
    return {
        ...state,
        needsQa: needsQa
    };
};

export const startQaMode = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isQaMode: true
    };
};

export const stopQaMode = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isQaMode: false
    };
};

export const startSaveQa = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isSavingQa: true
    };
};

export const finishedSaveQa = (state = INITIAL_STATE, action) => {
    const { isError } = action;
    if (isError) {
        return {
            ...state,
            isSavingQa: false,
            isErrorSavingQa: isError
        };
    }
    return {
        ...state,
        isSavingQa: false,
        isErrorSavingQa: false,
        qaDisplayValues: [],
        qaRunOnSave: []
    };
};

export const addAcceptedQaValues = (state = INITIAL_STATE, action) => {
    const { qaDisplayValues, qaRunOnSave } = state;
    const { displayValues, command } = action;
    return {
        ...state,
        qaAcceptedValues: [...qaDisplayValues, displayValues],
        qaRunOnSave: _.uniqBy([...qaRunOnSave, command], com => com.key)
    };
};

export const HANDLERS = {
    [Types.SET_NEED_QA]: setNeedQa,
    [Types.START_QA_MODE]: startQaMode,
    [Types.STOP_QA_MODE]: stopQaMode,
    [Types.START_SAVE_QA]: startSaveQa,
    [Types.FINISHED_SAVE_QA]: finishedSaveQa,
    [Types.ADD_ACCEPTED_QA_VALUES]: addAcceptedQaValues
};

export default createReducer(INITIAL_STATE, HANDLERS);

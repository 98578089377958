import _ from 'lodash';
import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const INITIAL_STATE = {
    chemicalAccounts: { isError: false, items: [] },
    isLoadingChemicalAccounts: false,
    chemAccountInfo: { isError: false, value: [] },
    isLoadingChemAccountInfo: false,
    chemicalAccountTabs: { items: [], isError: false },
    isLoadingChemicalAccountTabs: false,
    operators: { isError: false, items: [] },
    isLoadingOperators: false,
    chemicalAccountTabInfo: { isError: false, value: [] },
    isLoadingChemicalAccountTabInfo: false,
    isCreatingGenericTab: false,
    isUpdatingChemicalDrips: false,
    phaseTypes: [],
    isDeletingGenericTab: false,
    isUpdatingGenericTab: false,
    isDeletingUsageDrips: false,
    isLoadingAllChemicals: false,
    allChemicals: [],
    isLoadingSelectedChemicalInfo: false,
    selectedChemicalInfo: null,
    isUpdatingGenericTabInfo: false,
    genericTabInfo: []
};

export const requestChemicalAccounts = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingChemicalAccounts: true };
};

export const receiveChemicalAccounts = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return {
        ...state,
        isLoadingChemicalAccounts: false,
        chemicalAccounts: response
    };
};

export const requestChemicalAccountTabInfo = (
    state = INITIAL_STATE,
    action
) => {
    return { ...state, isLoadingChemicalAccountTabInfo: true };
};

export const createGenericTab = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isCreatingGenericTab: true
    };
};

export const createdGenericTab = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isCreatingGenericTab: false
    };
};

export const updateChemicalDrips = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingChemicalDrips: true
    };
};

export const updatedChemicalDrips = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingChemicalDrips: false
    };
};

export const updateGenericTab = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingGenericTab: true
    };
};

export const updatedGenericTab = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingGenericTab: false
    };
};

export const updateGenericTabInfo = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return {
        ...state,
        isUpdatingGenericTabInfo: true,
        genericTabInfo: response
    };
};

export const updatedGenericTabInfo = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingGenericTabInfo: false
    };
};

export const receiveChemicalAccountTabInfo = (
    state = INITIAL_STATE,
    action
) => {
    const { response } = action;

    return {
        ...state,
        isLoadingChemicalAccountTabInfo: false,
        chemicalAccountTabInfo: response
    };
};

export const requestChemAccountInfo = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingChemAccountInfo: true };
};

export const receiveChemAccountInfo = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return {
        ...state,
        isLoadingChemAccountInfo: false,
        chemAccountInfo: response
    };
};

export const requestChemicalAccountTabs = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingChemicalAccountTabs: true
    };
};
export const receiveChemicalAccountTabs = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return {
        ...state,
        chemicalAccountTabs: response,
        isLoadingChemicalAccountTabs: false
    };
};

export const requestOperators = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingOperators: true };
};

export const receiveOperators = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingOperators: false, operators: response };
};

export const phaseTypesRequested = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingPhaseTypes: true
    };
};

export const phaseTypesReceived = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingPhaseTypes: false,
        phaseTypes: action.response
    };
};

export const phaseTypesFailed = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: action.error
    };
};

export const deleteChemicalAccountGenericTab = (
    state = INITIAL_STATE,
    action
) => {
    return {
        ...state,
        isDeletingGenericTab: true
    };
};

export const deletedChemicalAccountGenericTab = (
    state = INITIAL_STATE,
    action
) => {
    return {
        ...state,
        isDeletingGenericTab: false
    };
};

export const deleteUsageDrips = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isDeletingUsageDrips: true
    };
};

export const deletedUsageDrips = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isDeletingUsageDrips: false
    };
};

export const requestAllChemicals = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingAllChemicals: true
    };
};
export const receiveAllChemicals = (state = INITIAL_STATE, action) => {
    const { chemicals } = action;
    return {
        ...state,
        allChemicals: chemicals,
        isLoadingAllChemicals: false
    };
};

export const requestSelectedChemicalInfo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingSelectedChemicalInfo: true
    };
};
export const receiveSelectedChemicalInfo = (state = INITIAL_STATE, action) => {
    const { chemicalInfo } = action;
    return {
        ...state,
        selectedChemicalInfo: chemicalInfo,
        isLoadingSelectedChemicalInfo: false
    };
};

export const HANDLERS = {
    [Types.REQUEST_CHEMICAL_ACCOUNTS]: requestChemicalAccounts,
    [Types.RECEIVE_CHEMICAL_ACCOUNTS]: receiveChemicalAccounts,
    [Types.REQUEST_CHEM_ACCOUNT_INFO]: requestChemAccountInfo,
    [Types.RECEIVE_CHEM_ACCOUNT_INFO]: receiveChemAccountInfo,
    [Types.REQUEST_CHEMICAL_ACCOUNT_TABS]: requestChemicalAccountTabs,
    [Types.RECEIVE_CHEMICAL_ACCOUNT_TABS]: receiveChemicalAccountTabs,
    [Types.REQUEST_CHEMICAL_ACCOUNT_TAB_INFO]: requestChemicalAccountTabInfo,
    [Types.RECEIVE_CHEMICAL_ACCOUNT_TAB_INFO]: receiveChemicalAccountTabInfo,
    [Types.REQUEST_OPERATORS]: requestOperators,
    [Types.RECEIVE_OPERATORS]: receiveOperators,
    [Types.CREATE_GENERIC_TAB]: createGenericTab,
    [Types.CREATED_GENERIC_TAB]: createdGenericTab,
    [Types.UPDATE_CHEMICAL_DRIPS]: updateChemicalDrips,
    [Types.UPDATED_CHEMICAL_DRIPS]: updatedChemicalDrips,
    [Types.PHASE_TYPES_REQUESTED]: phaseTypesRequested,
    [Types.PHASE_TYPES_RECEIVED]: phaseTypesReceived,
    [Types.PHASE_TYPES_FAILED]: phaseTypesFailed,
    [Types.DELETE_GENERIC_TAB]: deleteChemicalAccountGenericTab,
    [Types.DELETED_GENERIC_TAB]: deletedChemicalAccountGenericTab,
    [Types.UPDATE_GENERIC_TAB]: updateGenericTab,
    [Types.UPDATED_GENERIC_TAB]: updatedGenericTab,
    [Types.DELETE_USAGE_DRIPS]: deleteUsageDrips,
    [Types.DELETED_USAGE_DRIPS]: deletedUsageDrips,
    [Types.REQUEST_ALL_CHEMICALS]: requestAllChemicals,
    [Types.RECEIVE_ALL_CHEMICALS]: receiveAllChemicals,
    [Types.REQUEST_SELECTED_CHEMICAL_INFO]: requestSelectedChemicalInfo,
    [Types.RECEIVE_SELECTED_CHEMICAL_INFO]: receiveSelectedChemicalInfo,
    [Types.UPDATE_GENERIC_TAB_INFO]: updateGenericTabInfo,
    [Types.UPDATED_GENERIC_TAB_INFO]: updatedGenericTabInfo
};

export default createReducer(INITIAL_STATE, HANDLERS);

import { apiFetch } from '../../../utility'

export function getModule(dispatch, moduleName, preferredLang) {
    const url = `api/Portal/GetModule?moduleName=${moduleName}&preferredLang=${preferredLang}`;
    return apiFetch(dispatch, url);
}

export function getModulePhrases(dispatch, moduleName, preferredLang, fallbackLang) {
    const url = `api/Phrase/GetModulePhrases?moduleIdent=${moduleName}&preferredLang=${preferredLang}&fallbackLang=${fallbackLang}`;
    return apiFetch(dispatch, url);
}

export function saveModulePhrase(dispatch, moduleId, lang, phrases) {
    const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(phrases)
    };
    const url = `api/Phrase/SaveModulePhrases?moduleId=${moduleId}&lang=${lang}`;
    return apiFetch(dispatch, null, url, options);
}
import React from 'react';
//import _ from 'lodash';
//import './general.css';

function QAFieldComponent({
    style,
    children,
    isQaMode,
    isApproved,
    isInvalid,
    onClick
}) {
    const defaultStyle = {
        backgroundColor: 'var(--primary-on-color)',
        padding: '1.5em',
        borderRadius: '4px',
        marginLeft: '1.5em',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginBottom: '1.5em'
    };

    const qaStyle = isQaMode
        ? isInvalid
            ? {
                  border: '4px solid var(--error-color-light)',
                  padding: 'calc(1.5em - 4px)'
              }
            : isApproved
            ? {
                  border: '4px solid var(--success-color-light)',
                  padding: 'calc(1.5em - 4px)'
              }
            : {
                  border: '4px solid var(--warning-color-light)',
                  padding: 'calc(1.5em - 4px)'
              }
        : {};
    const actualStyle = style
        ? { ...defaultStyle, ...qaStyle, ...style }
        : { ...defaultStyle, ...qaStyle };

    return (
        <div
            onClick={() => !isInvalid && onClick && onClick()}
            style={{ ...actualStyle, ...qaStyle }}
        >
            {children}
        </div>
    );
}

export default QAFieldComponent;

import React, { Suspense } from 'react';
import _ from 'lodash';
import { Responsive } from 'semantic-ui-react';
import { Loader } from '../../common';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../../components';
import lazy from '../../utility/lazy';
import { ModuleMenu, ModuleTitlebar } from './components';
import './modulewrapper.css';

var ActiveModule = null;

class ModuleWrapperComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeModule: null,
            prevRoute: null
        };
    }

    componentDidMount() {
        const { hasLoadedAppContext, defaultModuleName } = this.props;
        const { moduleName } = this.props.match.params;
        if (hasLoadedAppContext) {
            this.setActiveModule(moduleName || defaultModuleName);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            defaultModuleName,
            fetchModulePhrases,
            selectedLang,
            selectedModule,
            selectedSubModule,
            setNeedQa
        } = this.props;
        const { moduleName, subModuleName } = this.props.match.params;

        if (prevProps.match.params.moduleName !== moduleName || 
            prevProps.match.params.subModuleName !== subModuleName) {
            this.setActiveModule(moduleName || defaultModuleName);
        }

        if (_.get(prevProps, 'selectedModule.name') !== _.get(selectedModule, 'name') ||
            _.get(prevProps, 'selectedSubModule.name') !== _.get(selectedSubModule, 'name')) {
            this.setActiveModule(_.get(selectedModule, 'name') || defaultModuleName);
        }

        if (selectedLang !== prevProps.selectedLang) {
            const module = this.getModuleByName(moduleName || defaultModuleName);
            if (module) {
                fetchModulePhrases(module.id, selectedLang);
            }
        }
    }

    setActiveModule = (moduleName) => {
        const {
            modules,
            setSelectedModule,
            setSelectedSubModule,
            fetchModulePhrases,
            selectedLang,
            isLoadingModulePhrases
        } = this.props;

        const module = _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(moduleName);
        });

        //TODO: handle if module does not exist!
        if (module) {
            const subModuleName = this.props.match.params.subModuleName || this.getDefaultSubModuleName(module);
            const subModule = subModuleName && !_.isEmpty(module.children) ? _.find(module.children, m => {
                return _.toLower(m.name) === _.toLower(subModuleName);
            }) : null;

            ActiveModule = lazy(() => import(`../../modules/${module.name}/index.js`), subModule ? subModule.name : module.name);

            if (!isLoadingModulePhrases && _.isEmpty(module.phrases)) {
                fetchModulePhrases(module.id, selectedLang);
            }
            this.setTitle(module);
            setSelectedSubModule(subModule);
            if (_.toLower(this.props.match.params.moduleName) !== _.toLower(moduleName) ||
                _.toLower(this.props.match.params.subModuleName) !== _.toLower(subModuleName)) {
                if (subModuleName) {
                    this.props.history.replace(`/${moduleName}/${subModuleName}`);
                } else {
                    this.props.history.replace(`/${moduleName}`);
                }
            }
        }
        setSelectedModule(module || null);
    }

    getDefaultSubModuleName = (module) => {
        if (_.isEmpty(module.children)) return null;
        const { menu } = this.props;
        const menuItem = _.find(menu, i => {
            return _.toLower(i.name) === _.toLower(module.name);
        });
        if (menuItem) {
            return _.get(_.head(menuItem.children), 'name');
        } else {
            const subModule = _.head(module.menu) || _.head(module.children);
            return _.get(subModule, 'name');
        }
    }

    setTitle = module => {
        const { config, portalPhrases } = this.props;
        const { subModuleName } = this.props.match.params;
        const portalTitle = _.find(portalPhrases, p => {
            return _.toLower(p.guid) === _.toLower(config.titleGuid);
        });

        const moduleTitle = _.find(portalPhrases, p => {
            return _.toLower(p.guid) === _.toLower(module.titleGuid);
        });
        if (subModuleName) {
            const subModule = _.find(module.children, c => {
                return _.toLower(c.name) === _.toLower(subModuleName);
            });
            if (subModule) {
                const subModuleTitle = _.find(portalPhrases, p => {
                    return _.toLower(p.guid) === _.toLower(subModule.titleGuid);
                });
                if (subModuleTitle) {
                    document.title = `${_.get(moduleTitle, 'value') ||
                        module.name} - ${_.get(subModuleTitle, 'value') ||
                        _.get(subModule, 'name')} - ${_.get(
                        portalTitle,
                        'value'
                    )}`;
                } else {
                    document.title = `${_.get(moduleTitle, 'value') ||
                        module.name} - ${_.get(portalTitle, 'value')}`;
                }
            }
        } else {
            document.title = `${_.get(moduleTitle, 'value') ||
                module.name} - ${_.get(portalTitle, 'value')}`;
        }
    };

    getModuleByName = (modules, moduleName) => {
        return _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(moduleName);
        });
    };

    handleSubMenuItemClick = newSubModuleName => {
        const { moduleName, subModuleName } = this.props.match.params;
        const { modules } = this.props;
        const module = this.getModuleByName(modules, moduleName);

        if (_.toLower(newSubModuleName) !== _.toLower(subModuleName)) {
            this.props.history.push(`/${module.name}/${newSubModuleName}`);
        }
    };

    getMenuItem = (item, id) => {
        if (_.isEmpty(item.children)) {
            return _.toLower(item.value) === _.toLower(id);
        }
        return _.some(item.children, function(child) {
            return _.toLower(child.value) === _.toLower(id);
        });
    };

    getSubModuleNameFromParams = () => {
        const { subModuleName } = this.props.match.params;
        if (subModuleName) {
            const subModuleItem = _.find(module.menu, function(o) {
                return o.value.toLowerCase() === subModuleName.toLowerCase();
            });
            return _.get(subModuleItem, 'value');
        }
        return null;
    };

    render() {
        const { moduleName } = this.props.match.params;
        const {
            selectedModule,
            hasLoadedAppContext,
            isLoadingModule,
            isLoadingModulePhrases
        } = this.props;

        if (
            !moduleName ||
            !hasLoadedAppContext ||
            isLoadingModule ||
            isLoadingModulePhrases
        ) {
            return this.renderLoader();
        } else {
            if (!selectedModule) {
                return <div />;
            }
            return (
                <React.Fragment>
                    <Responsive
                        minWidth={Responsive.onlyTablet.minWidth}
                        className="module-container"
                        fireOnMount
                    >
                        {this.renderDesktop()}
                    </Responsive>
                    <Responsive
                        maxWidth={Responsive.onlyMobile.maxWidth}
                        className="module-container mobile"
                        fireOnMount
                    >
                        {this.renderMobile()}
                    </Responsive>
                </React.Fragment>
            );
        }
    }

    renderDesktop = () => {
        const { prevRoute } = this.state;
        const { moduleName, subModuleName } = this.props.match.params;
        const {
            selectedModule,
            selectedSubModule,
            showReturnButton
        } = this.props;
        if (_.isEmpty(selectedModule.menu)) {
            return (
                <div className="module-main-container">
                    <div className="module-content-container">
                        {this.renderMessages()}
                        {ActiveModule && this.renderModule()}
                    </div>
                </div>
            );
        } else {
            if (!selectedSubModule) {
                return <div />;
            }
            if (
                _.some(selectedModule.menu, m => {
                    return _.toLower(m.value) === _.toLower(subModuleName);
                })
            ) {
                return (
                    <React.Fragment>
                        <ModuleTitlebar
                            module={selectedModule}
                            subModule={selectedSubModule}
                            showReturnButton={showReturnButton}
                            prevRoute={prevRoute}
                        />
                        <div className="module-main-container">
                            <div className="module-menu-container">
                                <ModuleMenu
                                    moduleName={moduleName}
                                    subModuleName={subModuleName}
                                    menu={selectedModule.menu}
                                    handleMenuItemClick={item =>
                                        this.handleSubMenuItemClick(item.value)
                                    }
                                />
                            </div>
                            <div className="module-content-container">
                                {this.renderMessages()}
                                {ActiveModule && this.renderModule()}
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <ModuleTitlebar
                            module={selectedModule}
                            subModule={selectedSubModule}
                            showReturnButton={showReturnButton}
                            prevRoute={prevRoute}
                        />
                        <div className="module-main-container">
                            <div className="module-content-container">
                                {this.renderMessages()}
                                {ActiveModule && this.renderModule()}
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
        }
    };

    renderMobile = () => {
        const { subModuleName } = this.props.match.params;
        const { prevRoute } = this.state;
        const {
            selectedModule,
            selectedSubModule,
            handleShowSidebar,
            handleHideSidebar,
            sidebarIsVisible,
            showReturnButton,
            notifications,
            updateReadNotifications,
            isLoadingNotifications,
            isNotificationsVisible,
            showNotifications
        } = this.props;
        /*const selectedModuleMenuChildren = _.get(
            _.find(menu, ['value', _.get(selectedModule, 'name')]),
            'children'
        );*/
        return (
            <React.Fragment>
                <ModuleTitlebar
                    module={selectedModule}
                    subModule={selectedSubModule}
                    subModuleName={subModuleName}
                    handleMenuItemClick={item =>
                        this.handleSubMenuItemClick(item.value)
                    }
                    showReturnButton={showReturnButton}
                    toggleSidebar={
                        sidebarIsVisible ? handleHideSidebar : handleShowSidebar
                    }
                    prevRoute={prevRoute}
                    {...{
                        notifications,
                        isLoadingNotifications,
                        updateReadNotifications,
                        isNotificationsVisible,
                        showNotifications
                    }}
                />
                {this.renderMessages()}
                <div className="module-main-container">
                    <div className="module-content-container">
                        {ActiveModule && this.renderModule()}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderModule = () => {
        console.log("RENDER_MODULE!!", this.state.activeModule);
        return (
            <Suspense fallback={this.renderLoader()}>
                <ActiveModule/>
            </Suspense>
        );
    };

    renderLoader = () => {
        return <Loader />;
    };

    renderMessages = () => {
        const { messages } = this.props;
        return (
            <div className="error-message-wrapper">
                {_.map(messages, (message, key) => {
                    return this.renderMessage(message, key);
                })}
            </div>
        );
    };

    renderMessage = (message, key) => {
        const { removeMessage } = this.props;
        if (message.type === 'error') {
            return (
                <ErrorMessage
                    key={key}
                    guid={message.guid}
                    header={message.title}
                    message={message.message}
                    onDismiss={guid => removeMessage(guid)}
                />
            );
        } else if (message.type === 'success') {
            return (
                <SuccessMessage
                    key={key}
                    guid={message.guid}
                    header={message.title}
                    message={message.message}
                    onDismiss={guid => removeMessage(guid)}
                />
            );
        } else {
            return (
                <WarningMessage
                    key={key}
                    guid={message.guid}
                    header={message.title}
                    message={message.message}
                    onDismiss={guid => removeMessage(guid)}
                />
            );
        }
    };
}

export default ModuleWrapperComponent;

import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Menu, Icon, Responsive, Dropdown, Loader, Button } from 'semantic-ui-react';
import {
    PortalPhrase,
    PhraseHeader,
    PhraseParagraph,
    PhraseMenuItem
} from '../../../components';
import { MobileNotification } from './';
import './components.css';

class ModuleTitlebar extends React.Component {
    handleReturnClick = () => {
        const { prevRoute, module } = this.props;
        const { moduleName } = this.props.match.params;
        if (prevRoute) {
            this.props.history.goBack();
        } else {
            this.props.history.push(
                `/${moduleName}/${_.get(module, 'config.defaultSubModule') ||
                    _.get(_.head(module.menu), 'name') ||
                    ' '}`
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                <Responsive
                    minWidth={Responsive.onlyTablet.minWidth}
                    className="module-titlebar-container"
                    fireOnMount
                >
                    {this.renderMobile()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    className="module-titlebar-container-mobile"
                    fireOnMount
                >
                    {this.renderMobile()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderDesktop = () => {
        const { module, subModule, showReturnButton } = this.props;
        return (
            <Menu className="module-titlebar" borderless>
                {showReturnButton && this.renderReturnButton()}
                <Menu.Item className="module-titlebar-item">
                    <PortalPhrase
                        defaultText={module.name}
                        guid={module.titleGuid}
                        hasText
                        style={{ margin: 'auto' }}
                    >
                        <PhraseHeader />
                    </PortalPhrase>
                    {subModule && this.renderSubModuleHeader()}
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item>
                        <Icon name="ellipsis horizontal" />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    };

    renderMobile = () => {
        const {
            module,
            subModule,
            showReturnButton,
            toggleSidebar,
            isLoadingNotifications,
            notifications,
            updateReadNotifications,
            isShowingNotifications,
            handleShowNotifications,
            handleHideNotifications
        } = this.props;
        const { subModuleName } = this.props.match.params;
        const phrase =
            _.get(module, 'config.defaultSubModule') === subModuleName
                ? module
                : _.isNull(subModule)
                ? module
                : subModule;
        return (
            <div className="module-titlebar">
                <div style={{ flex: 0 }}>
                    <Menu borderless secondary>
                        {showReturnButton ? (
                            this.renderReturnButton()
                        ) : (
                            <Menu.Item
                                name="openSidebar"
                                onClick={toggleSidebar}
                            >
                                <Icon name="sidebar" />
                            </Menu.Item>
                        )}
                    </Menu>
                </div>
                <div style={{ flex: 1 }}>
                    <PortalPhrase
                        defaultText={_.get(phrase, 'name')}
                        guid={_.get(phrase, 'titleGuid')}
                        hasText
                        className="module-title-mobile nowrap"
                    >
                        <PhraseMenuItem />
                    </PortalPhrase>
                </div>
                <div style={{ flex: 0 }}>
                    <Menu borderless secondary>
                        {this.renderNotificationsIcon()}
                    </Menu>
                </div>
            </div>
        );
    };

    renderNotificationsIcon = () => {
        const { isLoadingNotifications, isNotificationsVisible, notifications, showNotifications, hideNotifications } = this.props;

        return (
            <Menu.Item>
                {(isLoadingNotifications && <Loader active size="mini" />) || (
                    <Button
                        style={{ background: 'none', padding: 0 }}
                        onClick={() => {
                            if (isNotificationsVisible) {
                                hideNotifications();
                            } else {
                                showNotifications();
                            }
                        }}
                    >
                        <Icon name="bell" size="large" style={{ margin: 0 }} />
                        {/*{!_.isEmpty(newNotifications) &&
                            renderNewNotificationsCount(
                                _.size(newNotifications)
                            )}*/}
                    </Button>
                )}
            </Menu.Item>
        );
    }

    renderMenuDropdown = () => {
        const { module, subModule, handleMenuItemClick } = this.props;
        if (!subModule || _.isEmpty(_.get(module, 'menu'))) return null;
        return (
            <Dropdown
                item
                text={
                    <PortalPhrase
                        defaultText={subModule.name}
                        guid={subModule.phraseGuid}
                        hasText
                    >
                        <PhraseParagraph />
                    </PortalPhrase>
                }
                style={{
                    marginLeft: '0',
                    paddingLeft: '0',
                    fontWeight: 'bold'
                }}
            >
                <Dropdown.Menu>
                    {_.map(module.menu, function(item, i) {
                        return (
                            <Dropdown.Item
                                key={i}
                                active={
                                    _.toLower(item.value) ===
                                    _.toLower(subModule.name)
                                }
                                onClick={() => handleMenuItemClick(item)}
                            >
                                <PortalPhrase
                                    defaultText={item.name}
                                    guid={item.phraseGuid}
                                    hasText
                                >
                                    <PhraseParagraph />
                                </PortalPhrase>
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    renderReturnButton = () => {
        return (
            <Menu.Item name="Return" onClick={this.handleReturnClick}>
                <Icon name="chevron left" />
            </Menu.Item>
        );
    };

    renderSubModuleHeader = () => {
        const { subModule } = this.props;
        return (
            <React.Fragment>
                <span>&nbsp;|&nbsp;</span>
                <PortalPhrase
                    defaultText={subModule.name}
                    guid={subModule.titleGuid}
                    hasText
                    style={{ margin: 'auto' }}
                >
                    <PhraseHeader />
                </PortalPhrase>
            </React.Fragment>
        );
    };
}

export default withRouter(ModuleTitlebar);

// @app/common/sidebar/duck/reducers.js
import { createReducer } from 'reduxsauce'
import { Types } from './actions';

export const INITIAL_STATE = {
    notifications: [],
    isNotificationsVisible: false,
    isLoadingNotifications: false
}

export const showNotifications = (state = INITIAL_STATE, action) => {
    return { ...state, isNotificationsVisible: true }
}

export const hideNotifications = (state = INITIAL_STATE, action) => {
    return { ...state, isNotificationsVisible: false }
}

export const requestNotifications = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingNotifications: true };
};

export const receiveNotifications = (state = INITIAL_STATE, action) => {
    const { notifications } = action;
    return {
        ...state,
        notifications: notifications,
        isLoadingNotifications: false
    };
};

export const HANDLERS = {
    [Types.NOTIFICATIONS_SHOW_NOTIFICATIONS]: showNotifications,
    [Types.NOTIFICATIONS_HIDE_NOTIFICATIONS]: hideNotifications,
    [Types.NOTIFICATIONS_REQUEST_NOTIFICATIONS]: requestNotifications,
    [Types.NOTIFICATIONS_RECEIVE_NOTIFICATIONS]: receiveNotifications
}

export default createReducer(INITIAL_STATE, HANDLERS);
import React from 'react';
import { Loader } from '../';
import _ from 'lodash';

class LogoutComponent extends React.Component {
    componentDidMount() {
        this.props.doLogout();
    }

    componentDidUpdate(prevProps) {
        const { isLoggingOut } = this.props;
        if (!isLoggingOut && prevProps.isLoggingOut) {
            this.props.history.push('/signin');
        }
    }

    render() {
        return <div style={{height: '100vh'}}>
            <Loader />
        </div>;
    }
}

export default LogoutComponent;
import { createReducer } from 'reduxsauce';
import { Types } from './actions';
import _ from 'lodash';

export const INITIAL_STATE = {
    chemicals: { items: [], isError: false },
    isLoadingChemicals: true,
    isCreatingChemical: false,
    isErrorCreatingChemical: false,
    chemicalInfo: null,
    isLoadingChemicalInfo: false,
    chemicalVersionInfo: null,
    isLoadingChemicalVersion: false,
    isCreatingChemicalVersion: false,
    isErrorCreatingChemicalVersion: false,
    isDeletingChemical: false,
    isErrorDeletingChemical: false,
    isApprovingChemical: false,
    isErrorApprovingChemical: false,
    dropdownInfo: { isError: false },
    isLoadingDropdownInfo: false,
};

export const requestChemicals = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingChemicals: true,
    };
};
export const receiveChemicals = (state = INITIAL_STATE, action) => {
    const { chemicals } = action;
    return {
        ...state,
        chemicals: chemicals,
        isLoadingChemicals: false,
    };
};
export const createChemical = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isErrorCreatingChemical: false,
        isCreatingChemical: true,
    };
};
export const createdChemical = (state = INITIAL_STATE, action) => {
    const { result, isError } = action;
    if (isError) {
        return {
            ...state,
            isErrorCreatingChemical: isError,
            isCreatingChemical: false,
        };
    } else {
        return {
            ...state,
            chemicalInfo: result,
            isErrorCreatingChemical: false,
            isCreatingChemical: false,
        };
    }
};
export const requestChemicalInfo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingChemicalInfo: true,
    };
};
export const receiveChemicalInfo = (state = INITIAL_STATE, action) => {
    const { chemicalInfo } = action;
    return {
        ...state,
        chemicalInfo: chemicalInfo,
        chemicalVersions: {},
        isErrorCreatingChemical: false,
        isErrorCreatingChemicalVersion: false,
        isErrorDeletingChemical: false,
        isErrorApprovingChemical: false,
        isLoadingChemicalInfo: false,
    };
};
export const requestChemicalVersionInfo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingChemicalVersion: true,
    };
};
export const receiveChemicalVersionInfo = (state = INITIAL_STATE, action) => {
    const { chemicalVersionInfo } = action;
    return {
        ...state,
        chemicalVersionInfo: chemicalVersionInfo,
        isErrorCreatingChemical: false,
        isErrorCreatingChemicalVersion: false,
        isErrorDeletingChemical: false,
        isErrorApprovingChemical: false,
        isLoadingChemicalVersion: false,
    };
};
export const createNewChemicalVersion = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isErrorCreatingChemicalVersion: false,
        isCreatingChemicalVersion: true,
    };
};
export const createdNewChemicalVersion = (state = INITIAL_STATE, action) => {
    const { result, isError } = action;
    if (isError) {
        return {
            ...state,
            isErrorCreatingChemicalVersion: isError,
            isCreatingChemicalVersion: false,
        };
    } else {
        let obj = {};

        if (
            _.get(state, 'chemicalInfo.value.id') === _.get(result, 'value.id')
        ) {
            obj['chemicalInfo'] = result;
        }
        return {
            ...state,
            ...obj,
            chemicalVersionInfo: result,
            isErrorCreatingChemicalVersion: false,
            isCreatingChemicalVersion: false,
        };
    }
};
export const deleteChemical = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isErrorDeletingChemical: false,
        isDeletingChemical: true,
    };
};
export const deletedChemical = (state = INITIAL_STATE, action) => {
    const { isError } = action;
    return {
        ...state,
        isErrorDeletingChemical: isError,
        isDeletingChemical: false,
    };
};
export const approveChemical = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isApprovingChemical: true,
    };
};
export const approvedChemical = (state = INITIAL_STATE, action) => {
    const { isError } = action;
    return {
        ...state,
        isErrorApprovingChemical: isError,
        isApprovingChemical: false,
    };
};

export const requestDropdownInfo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingDropdownInfo: true,
    };
};
export const receiveDropdownInfo = (state = INITIAL_STATE, action) => {
    const { dropdownInfo } = action;
    return {
        ...state,
        dropdownInfo: dropdownInfo,
        isLoadingDropdownInfo: false,
    };
};

export const HANDLERS = {
    [Types.REQUEST_CHEMICALS]: requestChemicals,
    [Types.RECEIVE_CHEMICALS]: receiveChemicals,
    [Types.CREATE_CHEMICAL]: createChemical,
    [Types.CREATED_CHEMICAL]: createdChemical,
    [Types.REQUEST_CHEMICAL_INFO]: requestChemicalInfo,
    [Types.RECEIVE_CHEMICAL_INFO]: receiveChemicalInfo,
    [Types.REQUEST_CHEMICAL_VERSION_INFO]: requestChemicalVersionInfo,
    [Types.RECEIVE_CHEMICAL_VERSION_INFO]: receiveChemicalVersionInfo,
    [Types.CREATE_NEW_CHEMICAL_VERSION]: createNewChemicalVersion,
    [Types.CREATED_NEW_CHEMICAL_VERSION]: createdNewChemicalVersion,
    [Types.DELETE_CHEMICAL]: deleteChemical,
    [Types.DELETED_CHEMICAL]: deletedChemical,
    [Types.APPROVE_CHEMICAL]: approveChemical,
    [Types.APPROVED_CHEMICAL]: approvedChemical,
    [Types.REQUEST_DROPDOWN_INFO]: requestDropdownInfo,
    [Types.RECEIVE_DROPDOWN_INFO]: receiveDropdownInfo,
};

export default createReducer(INITIAL_STATE, HANDLERS);

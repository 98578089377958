import React from 'react';
import { Redirect } from 'react-router';
import EmailValidator from 'email-validator';
import _ from 'lodash';
import { GlobalPhrase, PortalPhrase, PhraseInput, PhraseButton, PhraseMessage, PhraseParagraph, PhraseHeader } from '../../components';
import { Responsive, Grid, Form, Segment, Image, Loader, Dimmer, Button } from 'semantic-ui-react';
import './auth.css';

class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ident: '',
            password: '',
            isPersistent: false,
            identIsError: false,
            returnUrl: '',
            search: '',
            isPasswordVisible: false,
            loginFailed: false,
            rememberUser: true
        }
    }

    componentDidMount() {
        const { authIdent, config } = this.props;

        this.setState({
            params: new URLSearchParams(this.props.location.search),
            authIdent: authIdent,
            ident: _.get(authIdent, 'ident') || '',
            isPasswordVisible: _.toLower(_.get(authIdent, 'type')) === 'venicloud',
            returnUrl: _.get(this.props, 'location.state.returnUrl'),
            search: _.get(this.props, 'location.state.search')
        });

        document.title = `Sign in | ${_.get(config, 'name') || 'VeniCloud'}`;
    }

    componentDidUpdate(prevProps) {
        const { authIdent, isAuthenticated, loginFailed, config } = this.props;
        const { returnUrl, search } = this.state;

        if (loginFailed && loginFailed !== prevProps.loginFailed) {
            this.setState({ loginFailed: true });
            setTimeout(() => { this.setState({ loginFailed: false }); }, 5000);
        }
        if (!isAuthenticated && authIdent !== prevProps.authIdent) {
            this.setState({
                ident: _.get(authIdent, 'ident') || '',
                isPasswordVisible: _.toLower(_.get(authIdent, 'type')) === 'venicloud'
            });
        }
        if (isAuthenticated !== prevProps.isAuthenticated && isAuthenticated) {
            if (returnUrl) {
                this.props.history.replace(`${returnUrl}${search}`);
            } else {
                this.props.history.replace(`/`);
            }
        }
        if (_.get(config, 'name') !== _.get(prevProps.config, 'name')) {
            document.title = `Sign in | ${_.get(config, 'name') || 'VeniCloud'}`;
        }
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if (name === 'ident') {
            this.setState({ password: '', isPasswordVisible: false, loginFailed: false });
        }
    }

    handleIdentOnBlur = (e) => {

    }

    handleLoginOnClick = () => {
        const { doLogin } = this.props;
        const { ident, password } = this.state;
        doLogin(ident, password, true);
    }

    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={Responsive.onlyTablet.minWidth} className='login-container' fireOnMount>
                    {this.renderDesktop()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} fireOnMount>
                    {this.renderMobile()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderDesktop = () => {
        const { isLoadingConfig, config, hasLoadedPortalPhrases } = this.props;
        if (isLoadingConfig || !config || !hasLoadedPortalPhrases) {
            return <Dimmer active inverted>
                       <Loader/>
                   </Dimmer>;
        }
        return (
            <Grid textAlign='center' verticalAlign='middle' style={{ height: '100%' }}>
                <Grid.Column style={{ maxWidth: '40em' }}>
                    {this.renderContent()}
                </Grid.Column>
            </Grid>
        );
    }

    renderMobile = () => {
        const { isLoadingConfig, isLoadingAuthIdent, isLoggingIn, isValidating, config, hasLoadedPortalPhrases } = this.props;
        if (isLoadingConfig || !config || !hasLoadedPortalPhrases) {
            return <Dimmer active inverted>
                       <Loader/>
                   </Dimmer>;
        }
        const showLoader = isLoadingAuthIdent || isLoggingIn || isValidating;
        return (
            <div className='login-container'>
                <div className='login-header-mobile'>
                    <Image src="cms.png" className='login-logo' />
                </div>
                <Dimmer.Dimmable dimmed={showLoader} className='login-content-mobile'>
                    <Dimmer active={showLoader} inverted>
                        <Loader/>
                    </Dimmer>
                    <PortalPhrase
                        defaultText='Welcome'
                        guid="6c5993ad-3d5b-444e-8d15-63a2df27bb1f"
                        isEditable={false}
                        hasText>
                        <PhraseHeader as='h2' textAlign='center' className='login-title' />
                    </PortalPhrase>
                    <PortalPhrase
                        defaultText='Sign in to your account'
                        guid="39eb62b9-5d33-4f4b-b816-b4cd0ddbb909"
                        isEditable={false}
                        hasText>
                        <PhraseParagraph style={{ textAlign:"center", marginBottom: "2em" }} className='login-description' />
                    </PortalPhrase>
                    {this.renderLoginForm(true)}
                </Dimmer.Dimmable>
            </div>
        );
    }

    renderContent = () => {
        const { config, isLoadingAuthIdent, isLoggingIn, isValidating } = this.props;
        return (
            <Segment.Group>
                <Segment className='login-logo-container'>
                    <Image src="cms.png" className='login-logo'/>
                </Segment>
                <Segment className='login-form-container' loading={isLoadingAuthIdent || isLoggingIn || isValidating}>
                    {this.renderLoginForm()}
                </Segment>
            </Segment.Group>
        );
    }

    renderLoginForm = (isMobile) => {
        const { isLoadingConfig, isLoadingAuthIdent, isLoggingIn, isValidating, config } = this.props;
        const { ident, password, identIsError, isPasswordVisible, loginFailed } = this.state;
        const isLoginButtonDisabled = isLoadingConfig || isLoadingAuthIdent || isValidating || isLoggingIn || !ident || identIsError || (isPasswordVisible && !password);
        return (
            <React.Fragment>
                <Form className='login-form'>
                    <PortalPhrase
                        defaultPlaceholder="E-mail"
                        guid="8e71c30a-cece-47f9-b15c-c260a9621c43"
                        isEditable={false}
                        hasPlaceholder>
                        <PhraseInput
                            name="ident"
                            value={ident}
                            icon='user'
                            iconPosition='left'
                            onChange={this.handleOnChange}
                            onBlur={this.handleIdentOnBlur}
                            invalid={this.state.identIsError} />
                    </PortalPhrase>
                    {isPasswordVisible && this.renderPasswordInput()}
                    {loginFailed && this.renderUnauthorizedMessage()}
                    <GlobalPhrase
                        defaultText={isPasswordVisible ? "Login" : "Next"}
                        guid={isPasswordVisible
                            ? "c311dbca-4153-4c16-afcf-b4a3f8490c37"
                            : "b7e2e285-9bd7-4e5c-85d6-1bb22d6e50f3"}
                        isEditable={false} hasText>
                        <PhraseButton
                            size='large'
                            disabled={isLoginButtonDisabled}
                            onClick={this.handleLoginOnClick}
                            className={isMobile ? 'login-button mobile' : 'login-button'}
                            fluid />
                    </GlobalPhrase>
                </Form>
            </React.Fragment>
        );
    }

    renderPasswordInput = () => {
        const { password } = this.state;
        return (
            <GlobalPhrase
                defaultPlaceholder="Password"
                guid="5c2868a0-1754-4de0-8dd5-6aa2b5b57363"
                hasPlaceholder
                isEditable={false}>
                <PhraseInput
                    value={password}
                    name="password"
                    icon='key'
                    type='password'
                    iconPosition='left'
                    onChange={this.handleOnChange}
                    role='alert'
                    autoFocus/>
            </GlobalPhrase>
        );
    }

    renderUnauthorizedMessage = () => {
        return (
            <GlobalPhrase
                defaultText="Invalid credentials"
                guid="cdb1bc50-42bc-4a64-b045-002624594c9a"
                hasText
                isEditable={false}>
                <PhraseMessage attached='bottom' negative role='alert'/>
            </GlobalPhrase>
        );
    }
}

export default LoginComponent;
// @app/modules/duck/operations.jsx
import { Creators } from './actions';
import _ from 'lodash';
import { saveModulePhrase } from './api';

const saveModulePhrasesAction = Creators.saveModulePhrases;
const handleMessageAction = Creators.appHandleMessage;

const saveModulePhrases = (moduleId, lang, phrases) => {
    return dispatch => {
        saveModulePhrase(dispatch, moduleId, lang, phrases).then(result => {
            if (result && !result.isError) {
                dispatch(saveModulePhrasesAction(moduleId, lang, phrases));
            } else {
                dispatch(handleMessageAction(_.get(result, 'errorMessage') || 'Failed to save phrase', 'error'));
            }
        });
    }
}

export default { saveModulePhrases }
// @app/components/Dashboard/duck/reducers.js
import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { Types } from './actions';

export const INITIAL_STATE = {
    wellbores: { isError: false, value: [] },
    isLoadingWellbores: false,
    wellboreInfo: {},
    isLoadingWellboreInfo: false,
    isCreatingWellbore: false,
    isDeletingWellbore: false,
    isErrorDeletingWellbore: false,
    isUpdatingWellbore: false,
    suppliers: { isError: false, value: [] },
    isLoadingSuppliers: false,
    supplierInfo: {},
    isLoadingSupplierInfo: false,
    isCreatingSupplier: false,
    isDeletingSupplier: false,
    isErrorDeletingSupplier: false,
    isUpdatingSupplier: false,
    operators: { isError: false, value: [] },
    isLoadingOperators: false,
    operatorInfo: {},
    isLoadingOperatorInfo: false,
    isCreatingOperator: false,
    isDeletingOperator: false,
    isErrorDeletingOperator: false,
    isUpdatingOperator: false,
    components: [],
    isLoadingComponents: false,
    isUpdatingComponents: false,
    updatedComponentsResponse: { isError: false },
    envCategories: { isError: false, value: [] },
    isLoadingEnvCategories: false
};

export const requestWellbores = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingWellbores: true };
};

export const receiveWellbores = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingWellbores: false, wellbores: response };
};

export const requestWellboreInfo = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingWellboreInfo: true };
};

export const receiveWellboreInfo = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingWellboreInfo: false, wellboreInfo: response };
};
export const createWellbore = (state = INITIAL_STATE, action) => {
    return { ...state, isCreatingWellbore: true };
};
export const createdWellbore = (state = INITIAL_STATE, action) => {
    const { response } = action;
    let wellbores;
    if (state.wellbores && state.wellbores.value) {
        wellbores = {
            ...state.wellbores,
            value: [...state.wellbores.value, response.value]
        };
    }
    return {
        ...state,
        isCreatingWellbore: false,
        wellboreInfo: response,
        wellbores
    };
};

export const deleteWellbore = (state = INITIAL_STATE, action) => {
    return { ...state, isDeletingWellbore: true };
};

export const deletedWellbore = (state = INITIAL_STATE, action) => {
    const { response, id } = action;
    return {
        ...state,
        isDeletingWellbore: false,
        isErrorDeletingWellbore: _.get(response, 'isError'),
        wellbores: {
            ...state.wellbores,
            value: _.filter(
                _.get(state, 'wellbores.value'),
                supp => supp.id !== id
            )
        }
    };
};

export const updateWellbore = (state = INITIAL_STATE, action) => {
    return { ...state, isUpdatingWellbore: true };
};

export const updatedWellbore = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isUpdatingWellbore: false, wellboreInfo: response };
};

export const requestSuppliers = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingSuppliers: true };
};

export const receiveSuppliers = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingSuppliers: false, suppliers: response };
};

export const requestSupplierInfo = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingSupplierInfo: true };
};

export const receiveSupplierInfo = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingSupplierInfo: false, supplierInfo: response };
};
export const createSupplier = (state = INITIAL_STATE, action) => {
    return { ...state, isCreatingSupplier: true };
};
export const createdSupplier = (state = INITIAL_STATE, action) => {
    const { response } = action;
    let suppliers;
    if (state.suppliers && state.suppliers.value) {
        suppliers = {
            ...state.suppliers,
            value: [...state.suppliers.value, response.value]
        };
    }
    return {
        ...state,
        isCreatingSupplier: false,
        supplierInfo: response,
        suppliers
    };
};

export const deleteSupplier = (state = INITIAL_STATE, action) => {
    return { ...state, isDeletingSupplier: true };
};

export const deletedSupplier = (state = INITIAL_STATE, action) => {
    const { response, id } = action;
    return {
        ...state,
        isDeletingSupplier: false,
        isErrorDeletingSupplier: _.get(response, 'isError'),
        suppliers: {
            ...state.suppliers,
            value: _.filter(
                _.get(state, 'suppliers.value'),
                supp => supp.id !== id
            )
        }
    };
};

export const updateSupplier = (state = INITIAL_STATE, action) => {
    return { ...state, isUpdatingSupplier: true };
};

export const updatedSupplier = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isUpdatingSupplier: false, supplierInfo: response };
};

export const requestOperators = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingOperators: true };
};

export const receiveOperators = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingOperators: false, operators: response };
};

export const requestOperatorInfo = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingOperatorInfo: true };
};

export const receiveOperatorInfo = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingOperatorInfo: false, operatorInfo: response };
};
export const createOperator = (state = INITIAL_STATE, action) => {
    return { ...state, isCreatingOperator: true };
};
export const createdOperator = (state = INITIAL_STATE, action) => {
    const { response } = action;
    let operators;
    if (state.operators && state.operators.value) {
        operators = {
            ...state.operators,
            value: [...state.operators.value, response.value]
        };
    }
    return {
        ...state,
        isCreatingOperator: false,
        operatorInfo: response,
        operators
    };
};

export const deleteOperator = (state = INITIAL_STATE, action) => {
    return { ...state, isDeletingOperator: true };
};

export const deletedOperator = (state = INITIAL_STATE, action) => {
    const { response, id } = action;
    if (response && !response.isError) {
        return {
            ...state,
            isDeletingOperator: false,
            isErrorDeletingOperator: _.get(response, 'isError'),
            operators: {
                ...state.operators,
                value: _.filter(
                    _.get(state, 'operators.value'),
                    supp => supp.id !== id
                )
            }
        };
    } else {
        return {
            ...state,
            isDeletingOperator: false,
            isErrorDeletingOperator: true
        };
    }
};

export const updateOperator = (state = INITIAL_STATE, action) => {
    return { ...state, isUpdatingOperator: true };
};

export const updatedOperator = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isUpdatingOperator: false, operatorInfo: response };
};

export const requestComponents = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingComponents: true };
};

export const receiveComponents = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingComponents: false, components: response };
};

export const updateComponents = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isUpdatingComponents: true,
        updatedComponentsResponse: { isError: false }
    };
};

export const updatedComponents = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return {
        ...state,
        isUpdatingComponents: false,
        updatedComponentsResponse: response
    };
};

export const requestEnvCategories = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingEnvCategories: true };
};

export const receiveEnvCategories = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingEnvCategories: false, envCategories: response };
};

export const HANDLERS = {
    [Types.REQUEST_WELLBORES]: requestWellbores,
    [Types.RECEIVE_WELLBORES]: receiveWellbores,
    [Types.UPDATE_WELLBORE]: updateWellbore,
    [Types.UPDATED_WELLBORE]: updatedWellbore,
    [Types.CREATE_WELLBORE]: createWellbore,
    [Types.CREATED_WELLBORE]: createdWellbore,
    [Types.DELETE_WELLBORE]: deleteWellbore,
    [Types.DELETED_WELLBORE]: deletedWellbore,
    [Types.REQUEST_WELLBORE_INFO]: requestWellboreInfo,
    [Types.RECEIVE_WELLBORE_INFO]: receiveWellboreInfo,
    [Types.REQUEST_SUPPLIERS]: requestSuppliers,
    [Types.RECEIVE_SUPPLIERS]: receiveSuppliers,
    [Types.UPDATE_SUPPLIER]: updateSupplier,
    [Types.UPDATED_SUPPLIER]: updatedSupplier,
    [Types.CREATE_SUPPLIER]: createSupplier,
    [Types.CREATED_SUPPLIER]: createdSupplier,
    [Types.DELETE_SUPPLIER]: deleteSupplier,
    [Types.DELETED_SUPPLIER]: deletedSupplier,
    [Types.REQUEST_SUPPLIER_INFO]: requestSupplierInfo,
    [Types.RECEIVE_SUPPLIER_INFO]: receiveSupplierInfo,
    [Types.REQUEST_OPERATORS]: requestOperators,
    [Types.RECEIVE_OPERATORS]: receiveOperators,
    [Types.UPDATE_OPERATOR]: updateOperator,
    [Types.UPDATED_OPERATOR]: updatedOperator,
    [Types.CREATE_OPERATOR]: createOperator,
    [Types.CREATED_OPERATOR]: createdOperator,
    [Types.DELETE_OPERATOR]: deleteOperator,
    [Types.DELETED_OPERATOR]: deletedOperator,
    [Types.REQUEST_OPERATOR_INFO]: requestOperatorInfo,
    [Types.RECEIVE_OPERATOR_INFO]: receiveOperatorInfo,
    [Types.REQUEST_COMPONENTS]: requestComponents,
    [Types.RECEIVE_COMPONENTS]: receiveComponents,
    [Types.UPDATE_COMPONENTS]: updateComponents,
    [Types.UPDATED_COMPONENTS]: updatedComponents,
    [Types.REQUEST_ENV_CATEGORIES]: requestEnvCategories,
    [Types.RECEIVE_ENV_CATEGORIES]: receiveEnvCategories
};

export default createReducer(INITIAL_STATE, HANDLERS);

import React from 'react';
//import _ from 'lodash';
import { Form, Popup, Icon, Loader, Message } from 'semantic-ui-react';
import '../phrase.css';

class PhraseDropdownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            originalValue: '',
            invalid: false,
            showErrorMessage: false,
            errorMessage: null
        };
    }

    componentDidMount() {
        const { value, error, errorMessage } = this.props;
        this.setState({
            value: value,
            originalValue: value,
            errorMessage: errorMessage
        });
        if (error) {
            this.handleShowErrorMessage(
                errorMessage || 'Something went wrong!'
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (value !== prevProps.value) {
            this.setState({ value: value || '', originalValue: value || '' });
        }
    }

    handleOnChange = (e, data) => {
        const { originalValue } = this.state;
        const { required, onSave, onChange, loading } = this.props;
        const { value, name } = data;
        if (loading) return;
        this.setState({ value: value });

        if (onSave) {
            if ((!required || value) && value !== originalValue) {
                onSave(name, value);
            }
        }

        if (onChange) {
            onChange(e, data);
        }
    };

    handleShowErrorMessage = errorMessage => {
        this.setState({
            showErrorMessage: true,
            errorMessage: errorMessage
        });
        setTimeout(
            function() {
                this.setState({ showErrorMessage: false, errorMessage: null });
            }.bind(this),
            3000
        );
    };

    render() {
        const { invalid } = this.state;
        const { hasDescription, required, maxWidth, error } = this.props;
        if (hasDescription) {
            return (
                <Form.Field
                    style={{ maxWidth: maxWidth }}
                    required={required}
                    error={error || invalid}
                >
                    {this.renderWithDescription()}
                </Form.Field>
            );
        }
        return (
            <Form.Field style={{ maxWidth: maxWidth }} required={required}>
                {this.renderWithoutDescription()}
            </Form.Field>
        );
    }

    renderWithoutDescription = () => {
        const { hasLabel } = this.props;
        return (
            <React.Fragment>
                {hasLabel && this.renderLabel()}
                {this.renderDropdown()}
            </React.Fragment>
        );
    };

    renderWithDescription = () => {
        const { description, hasLabel } = this.props;
        return (
            <React.Fragment>
                {hasLabel && this.renderLabel()}
                <Popup trigger={this.renderDropdown()} content={description} />
            </React.Fragment>
        );
    };

    renderDropdown = () => {
        const { showErrorMessage, value } = this.state;
        const {
            name,
            text,
            placeholder,
            additionLabel,
            additionPosition,
            allowAdditions,
            as,
            basic,
            button,
            clearable,
            closeOnBlur,
            closeOnChange,
            compact,
            deburr,
            defaultOpen,
            defaultSearchQuery,
            defaultSelectedLabel,
            defaultUpward,
            defaultValue,
            direction,
            disabled,
            floating,
            fluid,
            header,
            icon,
            inline,
            item,
            labeled,
            lazyLoad,
            loading,
            minCharacters,
            multiple,
            noResultsMessage,
            onAddItem,
            onBlur,
            onLabelClick,
            onSearchChange,
            open,
            openOnFocus,
            options,
            pointing,
            renderLabel,
            scrolling,
            search,
            searchInput,
            searchQuery,
            selectOnBlur,
            selectOnNavigation,
            selectedLabel,
            selection,
            simple,
            tabIndex,
            trigger,
            upward,
            wrapSelection,
            children,
            className
        } = this.props;
        return (
            <React.Fragment>
                <Form.Select
                    name={name}
                    value={value}
                    text={text}
                    placeholder={placeholder}
                    additionLabel={additionLabel}
                    additionPosition={additionPosition}
                    allowAdditions={allowAdditions}
                    as={as}
                    basic={basic}
                    button={button}
                    clearable={clearable}
                    closeOnBlur={closeOnBlur}
                    closeOnChange={closeOnChange}
                    compact={compact}
                    deburr={deburr}
                    defaultOpen={defaultOpen}
                    defaultSearchQuery={defaultSearchQuery}
                    defaultSelectedLabel={defaultSelectedLabel}
                    defaultUpward={defaultUpward}
                    defaultValue={defaultValue}
                    direction={direction}
                    disabled={disabled}
                    floating={floating}
                    fluid={fluid}
                    header={header}
                    icon={icon}
                    inline={inline}
                    item={item}
                    labeled={labeled}
                    lazyLoad={lazyLoad}
                    loading={loading}
                    minCharacters={minCharacters}
                    multiple={multiple}
                    noResultsMessage={noResultsMessage}
                    onAddItem={onAddItem}
                    onBlur={onBlur || this.handleOnBlur}
                    onChange={this.handleOnChange}
                    onLabelClick={onLabelClick}
                    onSearchChange={onSearchChange}
                    open={open}
                    openOnFocus={openOnFocus}
                    options={options}
                    pointing={pointing}
                    renderLabel={renderLabel}
                    scrolling={scrolling}
                    search={search}
                    searchInput={searchInput}
                    searchQuery={searchQuery}
                    selectOnBlur={selectOnBlur}
                    selectOnNavigation={selectOnNavigation}
                    selectedLabel={selectedLabel}
                    selection={selection}
                    simple={simple}
                    tabIndex={tabIndex}
                    trigger={trigger}
                    upward={upward}
                    wrapSelection={wrapSelection}
                    className={
                        className
                            ? `phrase-dropdown ${className}`
                            : 'phrase-dropdown'
                    }
                >
                    {children && this.renderChildren()}
                </Form.Select>
                {showErrorMessage && this.renderErrorMessage()}
            </React.Fragment>
        );
    };

    renderChildren = () => {
        const { children } = this.props;
        return children;
    };

    renderErrorMessage = () => {
        const { errorMessage } = this.state;
        return (
            <Message
                error
                header="Error"
                content={errorMessage || 'Error'}
                visible
            />
        );
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon"
                onClick={this.props.handleOpenEdit}
            />
        );
    };

    renderLoader = () => {
        return (
            <Loader active inline size="mini" className="phrase-label-loader" />
        );
    };
}

export default PhraseDropdownComponent;

import { apiFetch } from '../../../utility';

export function getNotifications(dispatch) {
    const url = `api/Notification/GetNotifications`;
    return apiFetch(dispatch, url);
}

export function sendReadNotifications(dispatch, readNotifications) {
    const url = `api/Notification/UpdateReadNotification`;
    const options = {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(readNotifications)
    };
    return apiFetch(dispatch, url, options);
}
import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import {
    ModulePhrase,
    PhraseInput,
    PhraseSpan
} from '../../components';
import { Icon, Button, Grid, Loader } from 'semantic-ui-react';
import './general.css';

export default function FilterListComponent({
    items,
    isLoadingItems,
    isErrorItems,
    single,
    parentFilter,
    newItemButtonText,
    onNewClick,
    selectedItems,
    setSelectedItems
}) {
    const [isAlphabetic, setAlphabetic] = useState(true);
    const [isAsc, setSortAsc] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [displayingSearchQuery, setDisplayingSearchQuery] = useState('');
    const [activeParents, setActiveParents] = useState([]);

    const parents = _.uniq(_.map(items, item => _.get(item, 'parent')));
    const showingItems = useMemo(() => {
        let sortedAndFilteredItems = items;
        if (parentFilter && !_.isEmpty(activeParents)) {
            sortedAndFilteredItems = _.filter(sortedAndFilteredItems, item =>
                _.some(
                    activeParents,
                    parent => parent === _.get(item, 'parent')
                )
            );
        }
        if (!_.isEmpty(searchQuery)) {
            sortedAndFilteredItems = _.filter(sortedAndFilteredItems, item =>
                _.startsWith(
                    _.toUpper(_.get(item, 'title')),
                    _.toUpper(searchQuery)
                )
            );
        }
        if (isAlphabetic && !isAsc) {
            sortedAndFilteredItems = _.sortBy(sortedAndFilteredItems, ['name']);
        } else if (isAlphabetic && isAsc) {
            sortedAndFilteredItems = _.reverse(
                _.sortBy(sortedAndFilteredItems, ['name'])
            );
        } else if (!isAlphabetic && !isAsc) {
            sortedAndFilteredItems = _.sortBy(sortedAndFilteredItems, [
                'updatedDate'
            ]);
        } else {
            _.reverse(_.sortBy(sortedAndFilteredItems, ['updatedDate']));
        }
        return sortedAndFilteredItems;
    }, [items, parentFilter, isAlphabetic, searchQuery, activeParents]);

    function handleSort(isAlpabetic, isAsc) {
        setAlphabetic(isAlpabetic);
        setSortAsc(isAsc);
    }

    const handleOnSearchChange = data => {
        const value = _.get(data, 'target.value');
        setDisplayingSearchQuery(value);
        _.debounce(() => setSearchQuery(value), 150, { maxWait: 300 })();
    };

    function handleParentClick(parent, isActive) {
        if (isActive) {
            setActiveParents(_.filter(activeParents, item => item !== parent));
            setSelectedItems(
                _.filter(selectedItems, item => item.parent !== parent)
            );
        } else {
            setActiveParents([parent, ...activeParents]);
        }
    }

    function handleItemClick(id, active) {
        if (active) {
            setSelectedItems(_.filter(selectedItems, item => item !== id));
        } else {
            if (single) {
                setSelectedItems([id]);
            } else {
                setSelectedItems([id, ...selectedItems]);
            }
        }
    }

    function render() {
        return (
            <div
                className="Filter-List"
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <div>
                    <ModulePhrase
                        defaultPlaceholder="Search"
                        guid="5dc93379-e742-4e4b-91c3-dc46b8723b3a"
                        hasPlaceholder
                    >
                        <PhraseInput
                            noField
                            className="Filter-search"
                            fluid
                            value={displayingSearchQuery}
                            size="mini"
                            iconPosition="left"
                            icon={
                                <Icon
                                    name="search"
                                    className="filter-search-icon"
                                />
                            }
                            onChange={handleOnSearchChange}
                        />
                    </ModulePhrase>
                </div>
                <Grid
                    className="filter-list-choices"
                    id="scroll-margin"
                    style={{
                        overflowY: 'auto',
                        height: '100%',
                        marginBottom: '0',
                        flexWrap: 'nowrap'
                    }}
                >
                    {isErrorItems && (
                        <Grid.Row style={{}}>
                            <ModulePhrase
                                defaultText="There was an error loading chemicals"
                                guid="7e1ed130-83b1-4eb4-b7fd-e873baa51dfa"
                                hasText
                            >
                                <PhraseSpan
                                    style={{
                                        color: 'var(--error-color)',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </ModulePhrase>
                        </Grid.Row>
                    )}
                    {_.isEmpty(showingItems) && !isLoadingItems && (
                        <Grid.Row style={{ justifyContent: 'center' }}>
                            <ModulePhrase
                                defaultText="No chemicals to display"
                                guid="83c943da-04b9-4473-aacb-27140c20b99d"
                                hasText
                            >
                                <PhraseSpan
                                    style={{
                                        color: 'var(--surface-on-color)',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </ModulePhrase>
                        </Grid.Row>
                    )}
                    {isLoadingItems && <Loader inline="centered" active />}
                    {!isLoadingItems && _.map(showingItems, renderOperator)}
                </Grid>
                {onNewClick && newItemButtonText && (
                    <Button
                        style={{
                            marginRight: '4px',
                            marginLeft: '4px',
                            marginTop: 'auto',
                            marginBottom: '4px',
                            backgroundColor: 'var(--primary-color-dark)',
                            borderRadius: '4px',
                            color: 'var(--primary-on-color)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingBottom: '8px',
                            paddingTop: '8px'
                        }}
                        onClick={onNewClick}
                    >
                        <div>
                            <Icon
                                name="plus"
                                style={{
                                    opacity: 1,
                                    color: 'var(--primary-color-dark)',
                                    backgroundColor: 'var(--primary-on-color)',
                                    width: '1em',
                                    borderRadius: '50%'
                                }}
                            />
                        </div>
                        <p
                            style={{
                                display: 'inline',
                                lineHeight: '1em',
                                color: 'var(--primary-on-color-dark) !important'
                            }}
                        >
                            {newItemButtonText}
                        </p>
                    </Button>
                )}
            </div>
        );
    }

    function renderOperator({ id, title, color }) {
        // eslint-disable-next-line eqeqeq
        const active = _.some(selectedItems, item => item == id);

        return (
            <Grid.Row
                key={id}
                className={
                    active
                        ? 'filter-list-choices-row active-row'
                        : 'filter-list-choices-row'
                }
                onClick={() => {
                    handleItemClick(id, active);
                }}
            >
                <Grid.Column className="filter-list-cell-status">
                    <Icon
                        name="circle"
                        className="filter-list-cell-status-icon"
                        style={{ color: color }}
                    />
                </Grid.Column>
                <Grid.Column className="filter-list-cell-name">
                    {title}
                </Grid.Column>
            </Grid.Row>
        );
    }

    return render();
}

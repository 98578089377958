import { lazy } from 'react'
//import _ from 'lodash';

//function retry(fn, retriesLeft = 5, interval = 1000) {
//    return new Promise((resolve, reject) => {
//        fn()
//            .then(resolve)
//            .catch((error) => {
//                setTimeout(() => {
//                    if (retriesLeft === 1) {
//                        // reject('maximum retries exceeded');
//                        reject(error);
//                        return;
//                    }

//                    // Passing on "reject" is the important part
//                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
//                }, interval);
//            });
//    });
//}

export default (resolver, name = 'Placeholder') => {
    return lazy(async () => {
        const resolved = await resolver();
        if (resolved[name]) {
            return { default: resolved[name] }
        } else {
            return { default: resolved['Missing'] }
        }
    });
}
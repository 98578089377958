// @app/common/sidebar/duck/operations.js
import { Creators } from './actions';
import { saveQaActions } from './api';

const setNeedQaAction = Creators.setNeedQa;
const startQaModeAction = Creators.startQaMode;
const stopQaModeAction = Creators.stopQaMode;
const startSaveQaAction = Creators.startSaveQa;
const finishedSaveQaAction = Creators.finishedSaveQa;
const addAcceptedQaValuesAction = Creators.addAcceptedQaValues;

const setNeedQa = need => {
    return dispatch => {
        dispatch(setNeedQaAction(need));
    };
};

const startQaMode = () => {
    return dispatch => {
        dispatch(startQaModeAction());
    };
};

const stopQaMode = () => {
    return dispatch => {
        dispatch(stopQaModeAction());
    };
};

const saveQa = actions => {
    return dispatch => {
        dispatch(startSaveQaAction());
        saveQaActions(dispatch, actions).then(result => {
            if (result && !result.isError) {
                dispatch(finishedSaveQaAction(result));
                if (window && window.location) {
                    window.location.reload();
                }
            } else {
                dispatch(finishedSaveQaAction(true));
            }
        });
    };
};

const addAcceptedQaValues = (displayValues, command) => {
    return dispatch => {
        dispatch(addAcceptedQaValuesAction(displayValues, command));
    };
};

export default {
    setNeedQa,
    startQaMode,
    stopQaMode,
    saveQa,
    addAcceptedQaValues
};

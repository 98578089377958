// @app/common/sidebar/duck/actions.js
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestMenu: [],
    receiveMenu: ['menu'],
    showSidebar: [],
    hideSidebar: []
});

export { Creators, Types };
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { authOperations } from './duck';
import LogoutComponent from './LogoutComponent';

const mapStateToProps = (state, props) => {
    const { isLoggingOut } = state.auth;
    return { isLoggingOut, ...props }
};

const mapDispatchToProps = (dispatch) => {
    const doLogout = () => {
        dispatch(authOperations.doLogout());
    };
    return { doLogout }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutComponent));
import React, { useState } from 'react';
import _ from 'lodash';
import { Icon, Button, Modal, Menu, Loader } from 'semantic-ui-react';
import moment from 'moment';
import {
    PortalPhrase,
    PhraseParagraph,
    PhraseHeader
} from '../../../components';

export default function QASectionComponent({
    needsQa,
    isQaMode,
    qaAcceptedValues,
    handleStartQa,
    handleStopQa,
    handleSaveQa,
    approverName,
    showQaReport,
    setShowQaReport,
    isSavingQa,
    isErrorSavingQa
}) {
    const [showQaOptions, setShowQaOptions] = useState(false);

    const errorQa =
        isErrorSavingQa === true ||
        _.some(isErrorSavingQa, response => response.isError === true);

    function renderError() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'var(--surface-on-color)',
                    minHeight: '40em'
                }}
            >
                <span
                    style={{
                        maxWidth: '60%',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Icon
                        name="times circle"
                        size="huge"
                        style={{ color: 'var(--error-color)' }}
                    />
                    <PortalPhrase
                        defaultText="There was an error approving one or more elements, please refresh and try again"
                        guid="fde3bab2-15b7-492e-9cc2-d46e7b79b8e2"
                        hasText
                    >
                        <PhraseHeader style={{ margin: '0' }} />
                    </PortalPhrase>
                </span>
            </div>
        );
    }

    if (showQaOptions || isQaMode) {
        return (
            <div
                style={{
                    backgroundColor: '#f7edce',
                    paddding: '0em 0.1em',
                    display: 'flex'
                }}
            >
                <Modal
                    open={showQaReport}
                    size="fullscreen"
                    basic
                    mountNode={document.querySelector('#site-container')}
                    onClose={() => setShowQaReport(false)}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <PortalPhrase
                            defaultText="QA report"
                            guid="50332356-8e94-4de9-8724-a815f27702bf"
                            hasText
                        >
                            <PhraseHeader
                                style={{
                                    backgroundColor: 'var(--secondary-color)',
                                    margin: '0 22em 0 0',
                                    padding: ' 0.5em 0.5em 0 0.5em'
                                }}
                            ></PhraseHeader>
                        </PortalPhrase>
                        <div
                            style={{
                                backgroundColor: 'var(--surface-color)',
                                borderColor: 'var(--secondary-color)',
                                borderStyle: 'solid',
                                borderWidth: '1.5em',
                                borderRadius: '2em 0em 0em 2em',
                                minWidth: '44em'
                            }}
                        >
                            {isSavingQa && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: '40em'
                                    }}
                                >
                                    <Loader inverted size="massive" active />
                                </div>
                            )}
                            {!isSavingQa && errorQa && renderError()}
                            {!isSavingQa && !errorQa && (
                                <React.Fragment>
                                    <div
                                        style={{
                                            display: 'flex',
                                            backgroundColor:
                                                'var(--surface-color)',
                                            color: 'var(--surface-on-color)',
                                            padding: '1em'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Icon name="user" size="huge" />
                                        </div>
                                        <span
                                            style={{
                                                flexGrow: 2,
                                                marginLeft: '1em',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    fontSize: '2em'
                                                }}
                                            >
                                                <PortalPhrase
                                                    defaultText="QA:"
                                                    guid="949bbe92-0560-4712-bae3-1c8460960bdc"
                                                    hasText
                                                >
                                                    <PhraseParagraph
                                                        style={{
                                                            margin: 0,
                                                            fontWeight: 'bold'
                                                        }}
                                                    />
                                                </PortalPhrase>
                                                <p>{approverName}</p>
                                            </span>
                                            <span>
                                                <PortalPhrase
                                                    defaultText="Time:"
                                                    guid="de4b59be-a6a1-402a-bf6f-8fefd62c4a60"
                                                    hasText
                                                >
                                                    <PhraseParagraph
                                                        style={{
                                                            fontSize: '1.2em',
                                                            display: 'inline'
                                                        }}
                                                    />
                                                </PortalPhrase>
                                                <p
                                                    style={{
                                                        fontSize: '1.2em',
                                                        display: 'inline'
                                                    }}
                                                >
                                                    {' '}
                                                    {moment().format(
                                                        'DD.MM.YYYY | kk:mm'
                                                    )}
                                                </p>
                                            </span>
                                        </span>
                                        {!_.isEmpty(qaAcceptedValues) && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        background: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '0 0.5em',
                                                        border:
                                                            '1px solid var(--material-gray-4)',
                                                        borderRadius: '0.6em'
                                                    }}
                                                    onClick={() => {
                                                        setShowQaReport(false);
                                                        handleSaveQa();
                                                    }}
                                                >
                                                    <Icon
                                                        name="save"
                                                        size="large"
                                                    />
                                                    <span>
                                                        <PortalPhrase
                                                            defaultText="send report"
                                                            guid="1df617bb-6611-4d38-8cd1-011bf21625c0"
                                                            hasText
                                                        >
                                                            <PhraseParagraph
                                                                style={{
                                                                    margin: '0'
                                                                }}
                                                            />
                                                        </PortalPhrase>
                                                        <PortalPhrase
                                                            defaultText="ctrl + s"
                                                            guid="fc46134b-097e-4f8e-ae7e-8967646c9fe8"
                                                            hasText
                                                        >
                                                            <PhraseParagraph
                                                                style={{
                                                                    margin: '0',
                                                                    fontSize:
                                                                        '0.7em'
                                                                }}
                                                            />
                                                        </PortalPhrase>
                                                    </span>
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            margin: '0 1em 1em 1em',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: 'var(--surface-on-color)',
                                            overflowY: 'auto',
                                            minHeight: '30em'
                                        }}
                                    >
                                        {_.map(qaAcceptedValues, valueGroup => {
                                            return _.map(
                                                valueGroup,
                                                (valueRow, index) => {
                                                    let icon;
                                                    if (valueRow.isEdited) {
                                                        icon = (
                                                            <Icon
                                                                name="pencil"
                                                                style={{
                                                                    color:
                                                                        'var(--warning-color)'
                                                                }}
                                                            />
                                                        );
                                                    } else if (
                                                        valueRow.isDeleted
                                                    ) {
                                                        icon = (
                                                            <Icon
                                                                name="close"
                                                                style={{
                                                                    color:
                                                                        'var(--error-color)'
                                                                }}
                                                            />
                                                        );
                                                    } else {
                                                        icon = (
                                                            <Icon
                                                                name="check"
                                                                style={{
                                                                    color:
                                                                        'var(--success-color)'
                                                                }}
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                marginTop:
                                                                    '0.5em'
                                                            }}
                                                        >
                                                            {icon}
                                                            {valueRow.path}
                                                            {!valueRow.isDeleted &&
                                                                '='}
                                                            <p
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    display:
                                                                        'inline'
                                                                }}
                                                            >
                                                                {valueRow.value}
                                                            </p>
                                                        </span>
                                                    );
                                                }
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Modal>
                {!isQaMode && (
                    <Menu.Item
                        style={{
                            backgroundColor: '#f2c94c',
                            margin: '0.4em 0 0.4em 0.4em',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '0.4em',
                            alignItems: 'center'
                        }}
                        onClick={handleStartQa}
                    >
                        <Icon
                            name="check"
                            size="large"
                            style={{ margin: '0 0.5em 0 0' }}
                        />

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                fontWeight: 'bold'
                            }}
                        >
                            <PortalPhrase
                                defaultText="Start godkjenning"
                                guid="de0800f3-c9b6-4342-b311-ea4f6c4b7db2"
                                hasText
                            >
                                <PhraseParagraph
                                    style={{
                                        margin: '0',
                                        whiteSpace: 'nowrap'
                                    }}
                                />
                            </PortalPhrase>
                            <PortalPhrase
                                defaultText="CTRL+Q"
                                guid="3558336f-d5d3-47e9-b099-c100f7ed6562"
                                hasText
                            >
                                <PhraseParagraph
                                    style={{ fontSize: '0.7em' }}
                                />
                            </PortalPhrase>
                        </span>
                    </Menu.Item>
                )}
                <Menu.Item
                    style={{
                        backgroundColor: '#f2c94c',
                        margin: '0.4em',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '0.4em',
                        alignItems: 'center'
                    }}
                    onClick={() => setShowQaReport(true)}
                >
                    <Icon
                        name="save"
                        size="large"
                        style={{ margin: '0 0.5em 0 0' }}
                    />
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            fontWeight: 'bold'
                        }}
                    >
                        <PortalPhrase
                            defaultText="Lagre & Rapporter"
                            guid="ecc539cc-ed84-45c4-82f3-ec999754cd08"
                            hasText
                        >
                            <PhraseParagraph
                                style={{ margin: '0', whiteSpace: 'nowrap' }}
                            />
                        </PortalPhrase>
                        <PortalPhrase
                            defaultText="CTRL+S"
                            guid="8176b5d5-ef8b-47a4-acdd-ae86cedcddd4"
                            hasText
                        >
                            <PhraseParagraph style={{ fontSize: '0.7em' }} />
                        </PortalPhrase>
                    </span>
                </Menu.Item>
                <Menu.Item
                    style={{
                        backgroundColor: '#f2c94c',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '1em'
                    }}
                    onClick={() => {
                        setShowQaOptions(false);
                        handleStopQa();
                    }}
                >
                    <Icon
                        name="eye"
                        size="big"
                        style={{
                            fontSize: '2em',
                            color: 'var(--warning-on-color-light)',
                            opacity: '1',
                            margin: '0'
                        }}
                    />
                </Menu.Item>
            </div>
        );
    } else {
        if (needsQa) {
            return (
                <Menu.Item
                    style={{
                        backgroundColor: '#f7edce',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '1em'
                    }}
                    onClick={() => setShowQaOptions(true)}
                >
                    <Icon
                        name="eye"
                        size="big"
                        style={{
                            fontSize: '2em',
                            color: 'var(--warning-color-light)',
                            opacity: '0.7',
                            margin: '0'
                        }}
                    />
                </Menu.Item>
            );
        }
        return (
            <Menu.Item style={{ flex: '0 1 0%', marginRight: '1.5em' }}>
                <Icon
                    name="eye slash outline"
                    size="big"
                    style={{
                        fontSize: '2em',
                        color: 'var(--success-color)',
                        opacity: '0.8',
                        margin: '0'
                    }}
                    onClick={() => setShowQaOptions(true)}
                />
            </Menu.Item>
        );
    }
}

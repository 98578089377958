import React from 'react';
import { Label, Icon, Header } from 'semantic-ui-react';
import '../phrase.css';

class PhraseLabelComponent extends React.Component {
    render() {
        const {
            text,
            active,
            as,
            attached,
            basic,
            circular,
            color,
            content,
            corner,
            detail,
            empty,
            floating,
            horizontal,
            iconLeft,
            iconRight,
            image,
            pointing,
            removeIcon,
            ribbon,
            size,
            tag,
            onClick,
            children,
            showEdit,
            onRemove,
            className
        } = this.props;
        return (
            <React.Fragment>
                <Label
                    text={text}
                    active={active}
                    as={as}
                    attached={attached}
                    basic={basic}
                    circular={circular}
                    color={color}
                    content={content}
                    corner={corner}
                    detail={detail}
                    empty={empty}
                    floating={floating}
                    horizontal={horizontal}
                    image={image}
                    pointing={pointing}
                    removeIcon={removeIcon}
                    ribbon={ribbon}
                    size={size}
                    tag={tag}
                    onClick={onClick}
                    onRemove={onRemove}
                    className={ className ? `phrase-label ${className}` : 'phrase-label' }>
                    {children}
                    {iconLeft && this.renderIcon(iconLeft)}
                    {text}
                    {iconRight && this.renderIcon(iconRight)}
                </Label>
                {showEdit && this.renderEditIcon()}
            </React.Fragment>
        );
    }

    renderIcon = name => {
        return <Icon name={name} />;
    };

    renderDescription = () => {
        const { description } = this.props;
        return <Header.Subheader>{description}</Header.Subheader>;
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon right"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseLabelComponent;
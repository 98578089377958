// @app/modules/duck/reducers.jsx
import _ from 'lodash';
import { createReducer } from 'reduxsauce'
import { Types } from './actions';
import { } from './classes';

export const INITIAL_STATE =
{
    moduleId: null,
    modulePhrases: null,
    isLoadingModule: false,
    hasLoadedModule: false,
    isWrapperModule: false
}

export const saveModulePhrases = (state = INITIAL_STATE, action) => {
    const { phrases } = action;
    const { modulePhrases } = state;

    const oldItems = _.filter(_.get(modulePhrases, 'items'), (phrase) => !_.some(phrases, (newPhrase) => newPhrase.guid === phrase.guid));
    const newItems = _.concat(oldItems, phrases);

    return {
        ...state,
        modulePhrases: {
            ...state.modulePhrases,
            items: newItems
        }
    }
}

export const HANDLERS = {
    [Types.SAVE_MODULE_PHRASES]: saveModulePhrases
}

export default createReducer(INITIAL_STATE, HANDLERS);
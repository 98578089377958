import { connect } from 'react-redux';
import LoaderComponent from './LoaderComponent';

const mapStateToProps = (state, props) => {
    const { isLoading, phrases } = state.app;
    return { isLoading, phrases, ...props }
};

const mapDispatchToProps = (dispatch) => {
    return { }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderComponent);
import React from 'react';
import _ from 'lodash';
import { Menu, Responsive } from 'semantic-ui-react';
import { SidebarMenuItem } from './';
import './components.css';

class SidebarMenu extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Responsive
                    minWidth={Responsive.onlyTablet.minWidth}
                    className="sidebar-menu-container"
                    fireOnMount
                >
                    {this.renderMenu()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    className="sidebar-menu-container"
                    fireOnMount
                >
                    {this.renderMenu()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderMenu = () => {
        const {
            subModuleName,
            moduleName,
            isSidebarVisible,
            menu,
            handleMenuItemClick
        } = this.props;
        return (
            <Menu className="sidebar-menu" icon="labeled" vertical borderless>
                {_.map(menu, (item,i) => <SidebarMenuItem
                    item={item}
                    key={i}
                    moduleName={moduleName}
                    isSidebarVisible={isSidebarVisible}
                    subModuleName={subModuleName}
                    onClick={handleMenuItemClick}
                />)}
            </Menu>
        );
    };
}

export default SidebarMenu;
import _ from 'lodash';
import { apiFetch } from '../../../utility';

export function getNotifications(dispatch) {
    const url = `api/Notification/GetNotifications`;
    return apiFetch(dispatch, url);
}

export function sendReadNotifications(dispatch, readNotifications) {
    const url = `api/Notification/UpdateReadNotification`;
    const options = {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(readNotifications)
    };
    return apiFetch(dispatch, url, options);
}

export function saveQaActions(dispatch, actions) {
    const url = 'api/Qa/Send';
    const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            _.map(actions, action => {
                return { key: action.key, body: JSON.stringify(action.body) };
            })
        )
    };
    console.log(options);
    return apiFetch(dispatch, url, options);
}

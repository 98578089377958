import React from 'react';
import _ from 'lodash';
import { Form, Loader, Segment, Dimmer } from 'semantic-ui-react';
import queryString from 'query-string'
import {
    GlobalPhrase,
    PortalPhrase,
    ModulePhrase,
    PhraseInput,
    PhraseDropdown,
    PhraseParagraph
} from '../';

class GetFileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        const { doGetFile } = this.props;
        const { moduleName, fileId } = this.props.match.params;
        const search = queryString.parse(this.props.location.search);
        console.log("FILE_ID", fileId);
        doGetFile(moduleName || '', fileId, search.isDownload || false);
    }

    render() {
        return (
            <Segment>
                <Dimmer active inverted>
                    <Loader inverted/>
                </Dimmer>
            </Segment>
        );
    }
}

export default GetFileComponent;
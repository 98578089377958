import React from 'react';
import _ from 'lodash';
import { Form, Popup, Icon, Message } from 'semantic-ui-react';
import '../phrase.css';

class PhraseInputComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            originalValue: '',
            invalid: false,
            showErrorMessage: false,
            errorMessage: null
        };
    }

    componentDidMount() {
        const { value, invalid, error, errorMessage } = this.props;
        this.setState({
            value: value || '',
            originalValue: value || '',
            invalid: invalid
        });
        if (error) {
            this.handleShowErrorMessage(
                errorMessage || 'Something went wrong!'
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { value, error, errorMessage, invalid } = this.props;
        if (value !== prevProps.value) {
            this.setState({ value: value || '', originalValue: value || '' });
        }
        if (invalid !== prevProps.invalid) {
            this.setState({ invalid: invalid });
        }
        if (
            error !== prevProps.error ||
            errorMessage !== prevProps.errorMessage
        ) {
            this.setState({ errorMessage: errorMessage });
            if (error) {
                this.handleShowErrorMessage(
                    errorMessage || 'Something went wrong!'
                );
            }
        }
    }

    handleOnChange = e => {
        const { onChange, readOnly, handleValueHigher } = this.props;
        const { invalid } = this.state;
        const { required } = this.props;
        const { value } = e.target;
        if (readOnly) return;
        if (!handleValueHigher) {
            this.setState({ value: value });
        }
        if (required && !value) {
            this.setState({ invalid: true });
        } else if (invalid && !this.props.invalid) {
            this.setState({ invalid: false });
        }
        if (onChange) {
            onChange(e);
        }
    };

    handleOnBlur = e => {
        const { originalValue } = this.state;
        const { required, onSave } = this.props;
        const { name, value } = e.target;
        if ((required && !value) || !onSave) return;
        if (value !== originalValue) {
            onSave(name, value);
        }
    };

    handleShowErrorMessage = errorMessage => {
        this.setState({
            showErrorMessage: true,
            errorMessage: errorMessage
        });
        setTimeout(
            function() {
                this.setState({ showErrorMessage: false, errorMessage: null });
            }.bind(this),
            3000
        );
    };

    render() {
        const { invalid } = this.state;
        const {
            hasDescription,
            required,
            maxWidth,
            error,
            noField
        } = this.props;
        if (hasDescription) {
            if (noField) {
                return this.renderWithDescription();
            }
            return (
                <Form.Field
                    style={{ maxWidth: maxWidth }}
                    required={required}
                    error={error || invalid}
                >
                    {this.renderWithDescription()}
                </Form.Field>
            );
        }
        if (noField) {
            return this.renderWithoutDescription();
        }
        return (
            <Form.Field style={{ maxWidth: maxWidth }} required={required}>
                {this.renderWithoutDescription()}
            </Form.Field>
        );
    }

    renderWithoutDescription = () => {
        const { hasLabel, showEdit } = this.props;
        return (
            <React.Fragment>
                {(hasLabel || showEdit) && this.renderLabel()}
                {this.renderInput()}
            </React.Fragment>
        );
    };

    renderWithDescription = () => {
        const { description, hasLabel, showEdit } = this.props;
        return (
            <React.Fragment>
                {(hasLabel || showEdit) && this.renderLabel()}
                <Popup trigger={this.renderInput()} content={description} />
            </React.Fragment>
        );
    };

    renderInput = () => {
        const { showErrorMessage, value, invalid } = this.state;
        const {
            name,
            placeholder,
            action,
            actionPosition,
            as,
            disabled,
            error,
            loading,
            fluid,
            focus,
            icon,
            iconPosition,
            inverted,
            labelPosition,
            onBlur,
            size,
            style,
            tabIndex,
            transparent,
            type,
            children,
            className,
            readOnly
        } = this.props;
        return (
            <React.Fragment>
                <Form.Input
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    action={action}
                    actionPosition={actionPosition}
                    as={as}
                    disabled={disabled}
                    error={error || invalid}
                    loading={loading}
                    fluid={fluid}
                    focus={focus}
                    icon={icon}
                    iconPosition={iconPosition}
                    inverted={inverted}
                    labelPosition={labelPosition}
                    size={size}
                    style={style}
                    tabIndex={tabIndex}
                    transparent={transparent}
                    type={type || 'text'}
                    onChange={this.handleOnChange}
                    onBlur={onBlur || this.handleOnBlur}
                    className={
                        className ? `phrase-input ${className}` : 'phrase-input'
                    }
                    readOnly={readOnly || loading}
                >
                    {children && this.renderChildren()}
                </Form.Input>
                {showErrorMessage && this.renderErrorMessage()}
            </React.Fragment>
        );
    };

    renderChildren = () => {
        const { children } = this.props;
        return (
            <React.Fragment>
                <input />
                {children}
            </React.Fragment>
        );
    };

    renderErrorMessage = () => {
        const { errorMessage } = this.state;
        return (
            <Message
                error
                header="Error"
                content={errorMessage || 'Error'}
                visible
            />
        );
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label}
                {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseInputComponent;

import React from 'react';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

class ErrorMessageComponent extends React.Component {
    render() {
        const { guid, message, header, onDismiss } = this.props;

        return (
            <Message onDismiss={() => onDismiss(guid)} header={header} content={message} error/>
        );
    }
}

export default ErrorMessageComponent;
import { apiFetch } from '../app/utility'

export function getAuthIdent(dispatch, ident, isPersistent) {
    const url = `api/Auth/GetAuthIdent?ident=${ident}&isPersistent=${isPersistent}`;
    return apiFetch(dispatch, url, null, true);
}

export function getPortalConfig(dispatch) {
    const url = `api/Portal/GetPortalConfig`;
    return apiFetch(dispatch, url, null, true);
}

export function getAppContext(dispatch) {
    const url = `api/Portal/GetAppContext`;
    return apiFetch(dispatch, url, null, true);
}

export function getGlobalPhrases(dispatch, lang) {
    const url = `api/Phrase/GetGlobalPhrases?preferredLang=${lang}`;
    return apiFetch(dispatch, url, null, true);
}

export function saveGlobalPhrase(dispatch, lang, phrases) {
    const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(phrases)
    };
    const url = `api/Phrase/SaveGlobalPhrase?lang=${lang}`;
    return apiFetch(dispatch, url, options);
}

export function getPortalPhrases(dispatch, lang) {
    const url = `api/Phrase/GetPortalPhrases?preferredLang=${lang}`;
    return apiFetch(dispatch, url, null, true);
}

export function savePortalPhrase(dispatch, lang, phrases) {
    const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(phrases)
    };
    const url = `api/Phrase/SavePortalPhrase?lang=${lang}`;
    return apiFetch(dispatch, url, options);
}

export function getModulePhrases(dispatch, moduleIdent, lang) {
    const url = `api/Phrase/GetModulePhrases?moduleIdent=${moduleIdent}&preferredLang=${lang}`;
    return apiFetch(dispatch, url, null, true);
}

export function saveModulePhrase(dispatch, moduleId, lang, phrases) {
    const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(phrases)
    };
    const url = `api/Phrase/SaveModulePhrase?moduleId=${moduleId}&lang=${lang}`;
    return apiFetch(dispatch, url, options);
}
import React from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import '../phrase.css';

class PhraseButtonComponent extends React.Component {
    render() {
        const { hasDescription } = this.props;
        if (hasDescription) {
            return this.renderWithDescription();
        }
        return this.renderButton();
    }

    renderWithDescription = () => {
        const { description } = this.props;
        return <Popup trigger={this.renderButton()} content={description} />;
    };

    renderButton = () => {
        const {
            name,
            text,
            active,
            animated,
            as,
            attached,
            basic,
            circular,
            className,
            color,
            compact,
            disabled,
            floated,
            fluid,
            icon,
            inverted,
            label,
            labelPosition,
            loading,
            negative,
            onClick,
            positive,
            primary,
            role,
            secondary,
            size,
            style,
            tabIndex,
            toggle,
            children,
            showEdit,
            maxWidth,
            type,
            divStyle
        } = this.props;
        return (
            <div style={{...divStyle, maxWidth: maxWidth }}>
                {showEdit && this.renderEditIcon()}
                <Button
                    name={name}
                    content={!children ? text : null}
                    active={active}
                    animated={animated}
                    as={as}
                    attached={attached}
                    basic={basic}
                    circular={circular}
                    className={
                        className
                            ? `phrase-button ${className}`
                            : 'phrase-button'
                    }
                    color={color}
                    compact={compact}
                    disabled={disabled}
                    floated={floated}
                    fluid={fluid}
                    icon={icon}
                    inverted={inverted}
                    label={label}
                    labelPosition={labelPosition}
                    loading={loading}
                    negative={negative}
                    onClick={onClick}
                    positive={positive}
                    primary={primary}
                    role={role}
                    secondary={secondary}
                    size={size}
                    style={style}
                    tabIndex={tabIndex}
                    toggle={toggle}
                    type={type}
                >
                    {children && this.renderChildren()}
                </Button>
            </div>
        );
    };

    renderChildren = () => {
        const { children } = this.props;
        return children;
    };

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseButtonComponent;

import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { GlobalPhrase, PhraseButton, PhraseParagraph } from '../../components';
import { Responsive, Icon, Header, Segment } from 'semantic-ui-react';

class NoAccessComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    handleReturnButtonOnClick = (e) => {
        this.props.history.push('/');
    }

    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={Responsive.onlyTablet.minWidth} fireOnMount>
                    {this.renderDesktop()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} fireOnMount>
                    {this.renderDesktop()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderDesktop = () => {
        return (
            <Segment placeholder>
                <Header icon>
                    <Icon name='ban' />
                    <GlobalPhrase
                        guid='2e831bf2-f711-4199-9b7b-962425fd43fa'
                        defaultText='You do not have access to this page.'
                        hasText
                    >
                        <PhraseParagraph />
                    </GlobalPhrase>
                </Header>
                <Segment.Inline>
                    <GlobalPhrase
                        guid='be579c6e-198d-4397-a864-bfa8efd2330e'
                        defaultText='Return'
                        hasText
                    >
                        <PhraseButton onClick={this.handleReturnButtonOnClick} primary/>
                    </GlobalPhrase>
                </Segment.Inline>
            </Segment>
        );
    }

    renderMobile = () => {
        return (
            <div>
            </div>
        );
    }
}

export default withRouter(NoAccessComponent);
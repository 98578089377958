import React from 'react';
import _ from 'lodash';
import { Menu, Icon } from 'semantic-ui-react';
import { PortalPhrase, PhraseMenuItem, PhraseParagraph } from '../../../components/';

class MenuItem extends React.Component {
    render() {
        const { moduleName, subModuleName, item, onClick } = this.props;
        const hasChildren = !_.isEmpty(_.compact(item.children));
        const isActive = _.toLower(moduleName) === _.toLower(item.value);
        return (
            <React.Fragment>
                {this.renderMenuItem(isActive, hasChildren, item.value, null, item.phraseGuid, item.icon, item.value)}
                {isActive && hasChildren && _.map(item.children, (c, i) => {
                    return this.renderMenuItem(_.toLower(subModuleName) === _.toLower(c.value), !_.isEmpty(_.compact(c.children)), c.value, item.value, c.phraseGuid, c.icon, i);
                })}
            </React.Fragment>
        );
    }

    renderMenuItem = (isActive, hasChildren, value, parentValue, guid, icon, key) => {
        const { onClick } = this.props;
        const className = parentValue ? 'sidebar-menu-item child' : hasChildren ? 'sidebar-menu-item' : 'sidebar-menu-item single';
        return (
            <div key={key} className={isActive ? `${className} active` : className} onClick={() => onClick(parentValue ? parentValue : value, parentValue ? value : null)}>
                <div style={{ width: '3.5em' }}>
                    {icon ? <Icon name={icon} size="large" /> : null}
                </div>
                <div style={{ flex: 1 }}>
                    <PortalPhrase
                        defaultText={value}
                        hasText
                        guid={guid}>
                        <PhraseParagraph/>
                    </PortalPhrase>
                </div>
                <div style={{ flex: 0 }}>
                    {hasChildren ? <Icon name={isActive ? 'chevron up' : 'chevron down'} size="small" /> : null}
                </div>
            </div>
        );
    }
}

export default MenuItem;
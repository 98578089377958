import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
    PhraseInput,
    PhraseButton,
    ModulePhrase,
    PhraseHeader,
    PhraseParagraph
} from '../../components';
import {
    Responsive,
    Form,
    Image,
    Modal,
    Input,
    Button,
    Accordion,
    Divider
} from 'semantic-ui-react';
import './general.css';

class ChooseRegistrationType extends React.Component {
    constructor(props) {
        super(props);
    }

    handleValueSelect = value => {
        const { valueSet, setValue } = this.props;
        setValue(value);
        valueSet();
    };

    render = () => {
        const { value } = this.props;
        return (
            <React.Fragment>
                <ModulePhrase
                    defaultText="Ønsker du innmelding etter avtale eller direkte?"
                    guid="1f128b9a-735c-4fc5-ab3a-524e76b4808d"
                    hasText
                >
                    <PhraseHeader as="h4" />
                </ModulePhrase>
                <Button.Group style={{ display: "flex" }}>
                    <ModulePhrase
                        defaultText="Avtale"
                        defaultplaceholder="3 uker"
                        guid="1b665af3-c0ee-430e-b191-24d8efacbf31"
                        hasText
                        hasPlaceholder
                    >
                        <PhraseButton
                            active={value === 'Avtale'}
                            onClick={() => this.handleValueSelect('Avtale')}
                            divStyle={{ flex: 1 }}
                            className="mitt-fatland-or-button-choice"
                            fluid
                        />
                    </ModulePhrase>
                    <Button.Or className="mitt-fatland-or-button" text="eller" />
                    <ModulePhrase
                        defaultText="Direkte"
                        defaultplaceholder="Første aktuelle dato"
                        guid="b947d4ef-dfd2-440c-9598-d98eda90f6c3"
                        hasText
                        hasPlaceholder
                    >
                        <PhraseButton
                            active={value == 'Direkte'}
                            onClick={() => this.handleValueSelect('Direkte')}
                            divStyle={{ flex: 1 }}
                            className="mitt-fatland-or-button-choice"
                            fluid
                        />
                    </ModulePhrase>
                </Button.Group>
            </React.Fragment>
        );
    };
}
export default ChooseRegistrationType;

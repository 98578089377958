// @app/common/sidebar/duck/actions.js
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    setNeedQa: ['needsQa'],
    startQaMode: [],
    stopQaMode: [],
    startSaveQa: [],
    finishedSaveQa: ['isError'],
    addAcceptedQaValues: ['displayValues', 'command']
});

export { Creators, Types };

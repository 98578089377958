export default (url, filename) => {
    let link = document.createElement('a');

    return fetch(url)
        .then((result) => {
            const header = result.headers.get('content-disposition');
            console.log('HEADER:', header, filename);
            if (filename == null) {
                filename = header.match(
                    /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/
                )[2];
                if (filename) filename = filename.replaceAll('"', '');
            }

            link.setAttribute('download', filename);

            return result.blob();
        })
        .then((blob) => {
            const url = URL.createObjectURL(blob);

            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(url);
        });
};

import { connect } from 'react-redux';
import FieldWrapperComponent from './FieldWrapperComponent';
import { fieldOperations } from './duck';

const mapStateToProps = (state, props) => {
    const { } = state.app;
    return {
        ...props
    }
};

const mapDispatchToProps = (dispatch) => {
    const fetchDataRefs = (moduleIdent, refIdent) => {
        return dispatch(fieldOperations.fetchDataRefs(moduleIdent, refIdent));
    };
    return { fetchDataRefs }
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldWrapperComponent)
import _ from 'lodash';

export const validateAuthProdReg = (ident, code) => {
    return new Promise(function (resolve, reject) {
        return fetch(`api/Auth/ValidateAuthProdReg?ident=${ident}${code ? `&code=${code}` : ''}`).then(response => {
            if (response.ok) {
                return response.json();
            }
            return null;
        }).then(result => {
            return resolve(result);
        }).catch(error => {
            return reject(error.message);
        });
    });
}

export const loginProdReg = (config, ident) => {
    return new Promise(function (resolve, reject) {
        if (ident) {
            const url = `${config.authority}/authorize?client_id=${config.clientId}&response_type=${config.responseType}&redirect_uri=${window.location.href.split('?')[0]}&scope=${config.scope}&suggestedUserId=${ident}`;
            window.location = url;
            return resolve();
        }
        return reject("Ident is missing");
    });
}
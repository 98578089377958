import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestWellbores: [],
    receiveWellbores: ['response'],
    requestWellboreInfo: [],
    receiveWellboreInfo: ['response'],
    createWellbore: [],
    createdWellbore: ['response'],
    updateWellbore: [],
    updatedWellbore: ['response'],
    deleteWellbore: [],
    deletedWellbore: ['response', 'id'],
    requestSuppliers: [],
    receiveSuppliers: ['response'],
    requestSupplierInfo: [],
    receiveSupplierInfo: ['response'],
    createSupplier: [],
    createdSupplier: ['response'],
    updateSupplier: [],
    updatedSupplier: ['response'],
    deleteSupplier: [],
    deletedSupplier: ['response', 'id'],
    requestOperators: [],
    receiveOperators: ['response'],
    requestOperatorInfo: [],
    receiveOperatorInfo: ['response'],
    createOperator: [],
    createdOperator: ['response'],
    updateOperator: [],
    updatedOperator: ['response'],
    deleteOperator: [],
    deletedOperator: ['response', 'id'],
    requestComponents: [],
    receiveComponents: ['response'],
    updateComponents: [],
    updatedComponents: ['response'],
    requestEnvCategories: [],
    receiveEnvCategories: ['response']
});

export { Creators, Types };

import { Creators } from './actions';
import { getNotifications, sendReadNotifications } from './api';

const showNotificationsAction = Creators.notificationsShowNotifications;
const hideNotificationsAction = Creators.notificationsHideNotifications;
const requestNotificationsAction = Creators.notificationsRequestNotifications;
const receiveNotificationsAction = Creators.notificationsReceiveNotifications;

const showNotifications = () => {
    return dispatch => {
        dispatch(showNotificationsAction());
    }
}

const hideNotifications = () => {
    return dispatch => {
        dispatch(hideNotificationsAction());
    }
}

const fetchNotifications = () => {
    return dispatch => {
        dispatch(requestNotificationsAction());
        getNotifications(dispatch).then(result => {
            if (result && !result.isError) {
                dispatch(receiveNotificationsAction(result.items));
            } else {
                dispatch(receiveNotificationsAction([]));
            }
        });
    };
};

//TODO: Check me! Should update redux state directly on success instead of fetching again?
const updateReadNotifications = readNotifications => {
    return dispatch => {
        sendReadNotifications(dispatch, readNotifications).then(result => {
            if (result && !result.isError) {
                fetchNotifications()(dispatch);
            }
        });
    };
};

export default {
    showNotifications,
    hideNotifications,
    fetchNotifications,
    updateReadNotifications
}
import React from 'react';
import _ from 'lodash';
import { Image, Label } from 'semantic-ui-react';
import randomColor from 'randomcolor';

class AvatarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render = () => {
        const { name, image, size, style } = this.props;
        const initial = name ? name.charAt(0).toUpperCase() : '';
        if (image) {
            return (
                <div className={`avatar-image ${size}`} style={style}>
                    <img src={image} />
                </div>
            );
        } else {
            return (
                <Label
                    circular
                    size={
                        size === 'tiny'
                            ? 'small'
                            : 'small'
                            ? 'large'
                            : 'medium'
                            ? 'big'
                            : 'large'
                            ? 'huge'
                            : 'big'
                    }
                    style={{
                        ...style,
                        color: '#fff',
                        backgroundColor: randomColor({
                            luminosity: 'dark',
                            seed: initial
                        })
                    }}
                >
                    {initial}
                </Label>
            );
        }
    };
}

export default AvatarComponent;

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appOperations } from '../../../../duck';
import PhraseComponent from './PhraseComponent';

class ModulePhraseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phrases: null
        };
    }

    componentDidMount() {
        const { selectedModule, guid } = this.props;
        if (selectedModule) {
            this.setPhrases(selectedModule, guid);
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedModule, guid } = this.props;
        if (selectedModule && !_.isEqual(prevProps.selectedModule, selectedModule)) {
            this.setPhrases(selectedModule, guid);
        }
    }

    setPhrases = (selectedModule, guid) => {
        const phrases = _.filter(selectedModule.phrases, p => { return p.guid === guid });
        this.setState({ phrases: phrases });
    }

    render() {
        const { phrases } = this.state;
        const {
            globalPhrases,
            isModulePhraseAdmin,
            isEditable,
            isPhraseEditMode,
            isSavingModulePhrases
        } = this.props;
        return (
            <PhraseComponent
                phrases={phrases}
                globalPhrases={globalPhrases}
                showEdit={isPhraseEditMode && isEditable && isModulePhraseAdmin}
                onSavePhrases={this.handleOnSavePhrases}
                isSaving={isSavingModulePhrases}
                {...this.props}
            />
        );
    }

    handleOnSavePhrases = phrases => {
        const { moduleName, selectedLang, doSaveModulePhrase } = this.props;
        doSaveModulePhrase(moduleName, selectedLang, phrases);
    };
}

const mapStateToProps = (state, props) => {
    const isModulePhraseAdmin = true;
    const { selectedLang, globalPhrases, isPhraseEditMode, isSavingModulePhrases, selectedModule } = state.app;
    const { moduleId  } = state.module;
    return {
        selectedLang,
        globalPhrases,
        moduleId,
        selectedModule,
        isModulePhraseAdmin,
        isPhraseEditMode,
        isSavingModulePhrases
    };
};

const mapDispatchToProps = dispatch => {
    const doSaveModulePhrase = (moduleId, lang, phrases) => {
        return dispatch(
            appOperations.doSaveModulePhrase(moduleId, lang, phrases)
        );
    };
    return { doSaveModulePhrase };
};

ModulePhraseComponent.defaultProps = {
    isEditable: true
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModulePhraseComponent);
import _ from 'lodash';
import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const INITIAL_STATE = {
    afds: { isError: false, items: [] },
    isLoadingAfd: false,
    afd: null,
    isLoadingAfds: false,
    isCreatingAfd: false,
    isErrorCreatingAfd: false,
    afdInfo: null,
    isLoadingAfdInfo: false,
    isDeletingAfd: false,
    isErrorDeletingAfd: false,
    isLoadingAfdTab: false,
    isCreatingAfdTab: false,
    isErrorCreatingAfdTab: false,
    afdTab: null,
    isDeletingAfdTab: false,
    isErrorDeletingAfdTab: false,
    operators: { isError: false, items: [] },
    isLoadingOperators: false,
    suppliers: { isError: false, value: [] },
    isLoadingSuppliers: false,
    chemicals: { items: [], isError: false },
    isLoadingChemicals: true,
    chemicalInfo: null,
    isLoadingChemicalInfo: false,
    wellbores: { isError: false, value: [] },
    isLoadingWellbores: false
};

export const requestAfd = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingAfd: true
    };
};

export const receiveAfd = (state = INITIAL_STATE, action) => {
    const { result } = action;

    return {
        ...state,
        afd: result,
        isLoadingAfd: false
    };
};

export const requestAfds = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingAfds: true
    };
};

export const receiveAfds = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        afds: result,
        isLoadingAfds: false
    };
};

export const createAfd = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isCreatingAfd: true
    };
};

export const createdAfd = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isCreatingAfd: false
    };
};

export const updateAfd = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isUpdatingAfd: true
    };
};

export const updatedAfd = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingAfd: false,
        isUpdatingAfdError: !result || result.isError
    };
};

export const deleteAfd = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isDeletingAfd: true
    };
};

export const deletedAfd = (state = INITIAL_STATE, action) => {
    const { response, id } = action;
    if (response && !response.isError) {
        return {
            ...state,
            isDeletingAfd: false,
            isErrorDeletingAfd: _.get(response, 'isError'),
            afds: {
                ...state.afds,
                items: _.filter(
                    _.get(state, 'afds.items'),
                    afd => afd.id !== id
                )
            }
        };
    } else {
        return {
            ...state,
            isDeletingAfd: false,
            isErrorDeletingAfd: true
        };
    }
};

export const requestAfdInfo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingAfdInfo: true
    };
};

export const receiveAfdInfo = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isLoadingAfdInfo: false,
        afdInfo: result
    };
};

export const requestAfdTab = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingAfdTab: true
    };
};

export const receiveAfdTab = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isLoadingAfdTab: false,
        isUpdatingAfdTabError: !result || result.isError,
        afdTab: result
    };
};

export const createAfdTab = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isCreatingAfdTab: true
    };
};

export const createdAfdTab = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        afdTab: result,
        isCreatingAfdTab: false
    };
};

export const updateAfdTab = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isUpdatingAfdTab: true
    };
};

export const updatedAfdTab = (state = INITIAL_STATE, action) => {
    const { result } = action;
    return {
        ...state,
        isUpdatingAfdTab: false,
        afdTab: result
    };
};

export const deleteAfdTab = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isDeletingAfdTab: true
    };
};

export const deletedAfdTab = (state = INITIAL_STATE, action) => {
    const { response, afdId, tabId } = action;
    if (
        response &&
        !response.isError &&
        _.get(state, 'afdInfo.value.id') === afdId &&
        _.get(state, 'afdTab.value.id') === tabId
    ) {
        return {
            ...state,
            isDeletingAfdTab: false,
            isErrorDeletingAfdTab: _.get(response, 'isError'),
            afdTab: {}
        };
    } else {
        return {
            ...state,
            isDeletingAfd: false,
            isErrorDeletingAfd: true
        };
    }
};

export const requestOperators = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingOperators: true };
};

export const receiveOperators = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingOperators: false, operators: response };
};

export const requestSuppliers = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingSuppliers: true };
};

export const receiveSuppliers = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingSuppliers: false, suppliers: response };
};

export const requestChemicals = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingChemicals: true
    };
};
export const receiveChemicals = (state = INITIAL_STATE, action) => {
    const { chemicals } = action;
    return {
        ...state,
        chemicals: chemicals,
        isLoadingChemicals: false
    };
};

export const requestChemicalInfo = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isLoadingChemicalInfo: true
    };
};
export const receiveChemicalInfo = (state = INITIAL_STATE, action) => {
    const { chemicalInfo } = action;
    return {
        ...state,
        chemicalInfo: chemicalInfo,
        chemicalVersions: {},
        isErrorCreatingChemical: false,
        isErrorCreatingChemicalVersion: false,
        isErrorDeletingChemical: false,
        isErrorApprovingChemical: false,
        isLoadingChemicalInfo: false
    };
};

export const requestWellbores = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingWellbores: true };
};

export const receiveWellbores = (state = INITIAL_STATE, action) => {
    const { response } = action;
    return { ...state, isLoadingWellbores: false, wellbores: response };
};

export const HANDLERS = {
    [Types.REQUEST_AFD]: requestAfd,
    [Types.RECEIVE_AFD]: receiveAfd,
    [Types.REQUEST_AFDS]: requestAfds,
    [Types.RECEIVE_AFDS]: receiveAfds,
    [Types.CREATE_AFD]: createAfd,
    [Types.CREATED_AFD]: createdAfd,
    [Types.UPDATE_AFD]: updateAfd,
    [Types.UPDATED_AFD]: updatedAfd,
    [Types.DELETE_AFD]: deleteAfd,
    [Types.DELETED_AFD]: deletedAfd,
    [Types.REQUEST_AFD_INFO]: requestAfdInfo,
    [Types.RECEIVE_AFD_INFO]: receiveAfdInfo,
    [Types.REQUEST_AFD_TAB]: requestAfdTab,
    [Types.RECEIVE_AFD_TAB]: receiveAfdTab,
    [Types.CREATE_AFD_TAB]: createAfdTab,
    [Types.CREATED_AFD_TAB]: createdAfdTab,
    [Types.UPDATE_AFD_TAB]: updateAfdTab,
    [Types.UPDATED_AFD_TAB]: updatedAfdTab,
    [Types.DELETE_AFD_TAB]: deleteAfdTab,
    [Types.DELETED_AFD_TAB]: deletedAfdTab,
    [Types.REQUEST_OPERATORS]: requestOperators,
    [Types.RECEIVE_OPERATORS]: receiveOperators,
    [Types.REQUEST_SUPPLIERS]: requestSuppliers,
    [Types.RECEIVE_SUPPLIERS]: receiveSuppliers,
    [Types.REQUEST_CHEMICALS]: requestChemicals,
    [Types.RECEIVE_CHEMICALS]: receiveChemicals,
    [Types.REQUEST_CHEMICAL_INFO]: requestChemicalInfo,
    [Types.RECEIVE_CHEMICAL_INFO]: receiveChemicalInfo,
    [Types.REQUEST_WELLBORES]: requestWellbores,
    [Types.RECEIVE_WELLBORES]: receiveWellbores
};

export default createReducer(INITIAL_STATE, HANDLERS);

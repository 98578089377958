import React from 'react';
import _ from 'lodash';
import { Responsive, Dimmer, Loader, Segment } from 'semantic-ui-react';
import './loader.css';

class LoaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        }
    }

    componentDidMount() {
        const { phrases } = this.props;
        this.setState({ text: _.get(_.find(phrases, ['guid', '02707d5b-1e37-47f9-8eb0-b8919fcacbfe']), 'value') });
    }

    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={Responsive.onlyTablet.minWidth} className='loader-container' fireOnMount>
                    {this.renderDesktopLoader()}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className='loader-container' fireOnMount>
                    {this.renderMobileLoader()}
                </Responsive>
            </React.Fragment>);
    }

    renderDesktopLoader= () => {
        const { children, text } = this.props;
        return (
            <Segment className='loader-content-container'>
                <Dimmer active inverted>
                    <Loader inverted/>
                </Dimmer>
            </Segment>
        );
    }

    renderMobileLoader = () => {
        const { children, text } = this.props;
        return (
            <Segment className='loader-content-container'>
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>
            </Segment>
        );
    }
}

export default LoaderComponent;
import {
    combineReducers
} from 'redux';
import {
    connectRouter
} from 'connected-react-router';
import {
    default as appReducer
} from './duck/reducers';
import {
    default as authReducer
} from './app/common/Auth/duck';
import {
    default as moduleReducer
} from './app/common/ModuleWrapper/duck';
import {
    default as sidebarReducer
} from './app/common/Sidebar/duck/reducers';
import {
    default as notificationsReducer
} from './app/common/Notifications/duck/reducers';
import {
    default as navBarReducer
} from './app/common/NavBar/duck/reducers';
import {
    default as privacyReducer
} from './app/common/Privacy/duck';
import {
    default as myPageReducer
} from './app/modules/MyPage/duck/reducers';
import {
    default as wellExpertiseHOCNFReducer
} from './app/modules/HOCNF/duck/reducers';
import {
    default as wellExpertiseAfDReducer
} from './app/modules/ApplicationForDischarge/duck/reducers';
import {
    default as wellExpertiseChemicalAccountingReducer
} from './app/modules/ChemicalAccounting/duck/reducers';
import {
    default as wellExpertiseAdministrationReducer
} from './app/modules/WellAdministration/duck/reducers';

const rootReducer = history => {
    const reducers = combineReducers({
        router: connectRouter(history),
        app: appReducer,
        auth: authReducer,
        module: moduleReducer,
        sidebar: sidebarReducer,
        navBar: navBarReducer,
        notifications: notificationsReducer,
        privacy: privacyReducer,
        myPage: myPageReducer,
        wellExpertiseAdministration: wellExpertiseAdministrationReducer,
        wellExpertiseHOCNF: wellExpertiseHOCNFReducer,
        wellExpertiseAfd: wellExpertiseAfDReducer,
        wellExpertiseChemicalAccounting: wellExpertiseChemicalAccountingReducer
    });
    return (state, action) => {
        if (
            action.type === 'LOGOUT_SUCCESS' ||
            action.type === 'LOGOUT_FAILED'
        ) {
            state = undefined;
        }
        return reducers(state, action);
    };
};

export default rootReducer;
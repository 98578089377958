import React from 'react';
import { Icon, Header } from 'semantic-ui-react';
import '../phrase.css';

class PhraseSpanComponent extends React.Component {
    render() {
        const {
            text,
            className,
            showEdit,
            maxWidth,
            onClick,
            icon,
            prefixText,
            suffixText,
            divStyle,
            style
        } = this.props;
        return (
            <div style={{ ...divStyle, maxWidth: maxWidth }}>
                {showEdit && this.renderEditIcon()}
                <span
                    className={className ? `phrase-span ${className}` : 'phrase-span'}
                    onClick={onClick}
                    style={style}
                >
                    {icon && this.renderIcon(icon)} {prefixText && this.renderPrefixText(prefixText)} {text} {suffixText && this.renderSuffixText(suffixText)} 
                </span>
            </div>
        );
    }

    renderIcon = name => {
        return <Icon name={name} />;
    };

    renderDescription = () => {
        const { description } = this.props;
        return <Header.Subheader>{description}</Header.Subheader>;
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderPrefixText = (prefixText) => {
        return `${prefixText} `;
    }

    renderSuffixText = (suffixText) => {
        return ` ${suffixText}`;
    }


    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon right"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseSpanComponent;
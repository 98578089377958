import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Responsive, Image, Dimmer, Loader, Divider } from 'semantic-ui-react';
import { SidebarMenu } from './components';
import { DeviceTypes } from '../../utility/deviceTypes';
import './sidebar.css';
import { PortalPhrase, PhraseButton } from '../../components';

class SidebarComponent extends React.Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const { deviceType, isSidebarVisible } = this.props;
        if (isSidebarVisible !== prevProps.isSidebarVisible && deviceType === DeviceTypes.mobile) {
            if (isSidebarVisible) {
                document.body.style.position = 'fixed';
            } else {
                document.body.style.position = '';
            }
        }
    }

    handleClickOutside = e => {
        const { deviceType, isSidebarVisible } = this.props;
        if (isSidebarVisible && deviceType === DeviceTypes.mobile && this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.handleHideSidebar();
        } else if (isSidebarVisible) {
            e.stopPropagation();
        }
    };

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleToggleSidebar = () => {
        if (this.props.isSidebarVisible) {
            this.props.handleHideSidebar();
        } else {
            this.props.handleShowSidebar();
        }
    };

    //handleMenuItemClick = newModuleName => {
    //    const { modules, deviceType } = this.props;
    //    const { moduleName, subModuleName, foo } = this.props.match.params;
    //    const module = _.find(modules, m => {
    //        return _.toLower(m.name) === _.toLower(newModuleName);
    //    });
    //    const defaultSubModuleName =
    //    _.get(module, 'config.defaultSubModule') ||
    //    _.get(module, 'menu[0].value');
    //    if (!_.isEmpty(_.get(module, 'menu'))) {
    //        if (
    //            _.toLower(newModuleName) !== _.toLower(moduleName) ||
    //            (subModuleName &&
    //                !_.some(
    //                    module.menu,
    //                    m => _.toLower(m.name) === _.toLower(subModuleName)
    //                ))
    //        ) {
    //            this.props.history.push(
    //                `/${newModuleName}/${defaultSubModuleName}`
    //            );
    //        }
    //    } else if (_.toLower(newModuleName) !== _.toLower(moduleName) || (subModuleName && subModuleName !== defaultSubModuleName)
    //    ) {
    //        this.props.history.push(`/${newModuleName}`);
    //    }
    //    else if (foo) {
    //        if (defaultSubModuleName){
    //            this.props.history.push(
    //                `/${newModuleName}/${defaultSubModuleName}`
    //            );
    //        } else {
    //            this.props.history.push(`/${newModuleName}`);
    //        }
    //    }
    //    if (deviceType === DeviceTypes.mobile) {
    //        this.props.handleHideSidebar();
    //    }
    //};

    handleSubMenuItemClick = (newModuleName, newSubModuleName) => {
        const { deviceType } = this.props;
        
        const { moduleName, subModuleName } = this.props.match.params;
        if (moduleName !== newModuleName || subModuleName !== newSubModuleName) {
            this.props.history.push(`/${newModuleName}/${newSubModuleName}`);
        }
        if (deviceType === DeviceTypes.mobile) {
            this.props.handleHideSidebar();
        }
    };

    handleLogoClick = () => {
        const { defaultModuleName } = this.props;
        this.props.history.push(`/${defaultModuleName}`);
    };

    handleSignOutClick = () => {
        this.props.history.push(`/SignOut`);
    }

    handleMenuItemClick = (moduleName, subModuleName) => {
        if (subModuleName) {
            this.props.history.push(`/${moduleName}/${subModuleName}`);
        } else {
            this.props.history.push(`/${moduleName}`);
        }
    }

    render() {
        const { isSidebarVisible } = this.props;
        return (
            <div ref={this.setWrapperRef}>
                <Responsive
                    minWidth={Responsive.onlyTablet.minWidth}
                    className={
                        isSidebarVisible
                            ? 'sidebar-container'
                            : 'sidebar-container collapse'
                    }
                    fireOnMount
                    aria-hidden={!isSidebarVisible}
                >
                    {this.renderSidebarDesktop()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    className={
                        isSidebarVisible
                            ? 'sidebar-container mobile'
                            : 'sidebar-container mobile hide'
                    }
                    fireOnMount
                    aria-hidden={!isSidebarVisible}
                    
                >
                    {this.renderSidebarMobile()}
                </Responsive>
            </div>
        );
    }

    renderSidebarDesktop = () => {
        const { moduleName, subModuleName } = this.props.match.params;
        const { menu, isSidebarVisible, modules } = this.props;
        return (<React.Fragment>
                    {this.renderSidebarHeader()}
                    <SidebarMenu
                        modules={modules}
                        menu={menu}
                        moduleName={moduleName}
                        subModuleName={subModuleName}
                        isSidebarVisible={isSidebarVisible}
                        handleMenuItemClick={this.handleMenuItemClick}/>
                </React.Fragment>);
    };

    renderSidebarMobile = () => {
        const { moduleName, subModuleName } = this.props.match.params;
        const { menu, isSidebarVisible, modules } = this.props;
        return (
            <React.Fragment>
                {this.renderSidebarHeader()}
                <SidebarMenu
                    modules={_.map(modules, modul => {
                        return { name: modul.name, menu: modul.menu };
                    })}
                    menu={menu}
                    moduleName={moduleName}
                    subModuleName={subModuleName}
                    isSidebarVisible={isSidebarVisible}
                    handleItemClick={item =>
                        this.handleMenuItemClick(item.value)
                    }
                    handleSubItemClick={this.handleSubMenuItemClick}
                />
                <div className="sidebar-footer" >
                    <PortalPhrase
                        defaultText="Logg ut"
                        guid="ac3d777c-3add-43a6-a23e-6e880dd30866"
                        hasText
                        divStyle={{display: 'flex',
                                marginTop: '0.4em'}}
                    >
                        <PhraseButton className='sidebar-footer-mobile-logout' icon="log out" onClick={this.handleSignOutClick}/>
                    </PortalPhrase>
                </div>
                </React.Fragment>
        );
    };

    renderSidebarHeader = () => {
        const { config } = this.props;
        return (
            <div
                className="sidebar-header-container"
                onClick={this.handleLogoClick}
            >
                <img
                    src="cms.png"
                    // src={_.get(config, 'logo')}
                    className="sidebar-logo"
                    verticalAlign="middle"
                    alt={_.get(config, 'name') + ' logo'}
                />
            </div>
        );
    };

    renderSidebarFooter = () => {
        return (
            <div className="sidebar-footer">
                <div className="left">Powered by VeniCloud</div>
                <div className="right" />
            </div>
        );
    };
}

export default SidebarComponent;

// @app/duck/actions.jsx
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    appRequestAuthIdent: [],
    appReceiveAuthIdent: ['authIdent'],
    appHandleMessage: ['messageText', 'messageType', 'messageTitle'],
    appRemoveMessage: ['guid'],
    appRequestPortalConfig: [],
    appReceivePortalConfig: ['config'],
    appRequestAppContext: [],
    appReceiveAppContext: ['appContext'],
    appSetSelectedModule: ['module'],
    appSetSelectedSubModule: ['subModule'],
    appRequestGlobalPhrases: [],
    appReceiveGlobalPhrases: ['phrases'],
    appRequestSaveGlobalPhrase: [],
    appReceiveSaveGlobalPhrase: ['lang', 'phrases'],
    appRequestPortalPhrases: [],
    appReceivePortalPhrases: ['phrases'],
    appRequestSavePortalPhrase: [],
    appReceiveSavePortalPhrase: ['lang', 'phrases'],
    appRequestModulePhrases: [],
    appReceiveModulePhrases: ['moduleIdent', 'phrases'],
    appRequestSaveModulePhrase: [],
    appReceiveSaveModulePhrase: ['lang', 'phrases'],
    appSetDeviceType: ['deviceType'],
    appSetSelectedLang: ['lang'],
    appSetPhraseEditMode: ['isEdit'],
    appAddNavigationPathElement: ['element'],
    appSetNavigationPath: ['path'],
    appSetShowReturnButton: ['show']
});

export { Creators, Types };
import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import {
    PhraseInput,
    PhraseButton,
    GlobalPhrase,
    PhraseHeader,
    ModulePhrase,
    PhraseParagraph
} from '../../components';
import {
    Responsive,
    Form,
    Image,
    Modal,
    Input,
    Button,
    Divider
} from 'semantic-ui-react';
import './general.css';

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            filePreview: './placeholder.png'
        };
    }

    componentDidMount() {
        const { image } = this.props;
        if (image) {
            this.setState({ filePreview: image });
        }
    }

    handleEditFileOnChange = e => {
        const { files } = e.target;
        console.log(files);
        if (!_.isEmpty(files)) {
            this.setState({
                file: files[0],
                filePreview: URL.createObjectURL(files[0])
            });
        }
    };

    handleEditFileOnClose = e => {
        const { onClose, image } = this.props;
        this.setState({
            file: null,
            filePreview: image || './placeholder.png'
        });
        onClose(e);
    };

    handleEditFileOnSubmit = (e, data) => {
        const { onSubmit } = this.props;
        e.preventDefault();
        onSubmit(this.state.file);
    };

    handleFileSelectOnClick = e => {
        var input = document.getElementById('file-select-input');
        input.click();
    };

    render = () => {
        const { filePreview, file } = this.state;
        const { open, circular } = this.props;
        return (
            <Modal
                open={open}
                onClose={this.handleEditFileOnClose}
                closeIcon
                mountNode={document.getElementById('site-container')}
            >
                <Modal.Header>
                    <GlobalPhrase
                        defaultText="Velg en fil"
                        guid="3d3226ed-e92a-4d83-a3ee-446ce8f65b4d"
                        hasText
                    >
                        <PhraseHeader />
                    </GlobalPhrase>
                </Modal.Header>

                <Modal.Content>
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {this.renderPreviewImage(filePreview, circular)}
                    </span>
                    <Form
                        onSubmit={this.handleEditFileOnSubmit}
                        style={{ margin: '2em', marginTop: '0em' }}
                    >
                        <div className="upload-input">
                            <Input
                                id="file-select-input"
                                type="file"
                                onChange={this.handleEditFileOnChange}
                                style={{ visibility: 'hidden' }}
                            />
                        </div>
                        <Form.Group style={{ margin: '0' }}>
                            <ModulePhrase
                                defaultText="Velg"
                                guid="d4edfadf-c699-4ff6-8486-2e9cb6fe7cc4"
                                hasText
                            >
                                <PhraseButton
                                    type="button"
                                    onClick={this.handleFileSelectOnClick}
                                    className="primary-button"
                                />
                            </ModulePhrase>
                            <p
                                style={{
                                    marginLeft: '0.7em',
                                    paddingTop: '0.6em',
                                    fontSize: '1.2em'
                                }}
                            >
                                {_.get(file, 'name')}
                            </p>
                        </Form.Group>
                        <Divider
                            style={{
                                marginTop: '0'
                            }}
                        />
                        <Form.Group
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '0'
                            }}
                        >
                            <ModulePhrase
                                defaultText="Lukk"
                                guid="9363afe2-8901-4b07-9e96-2f8cc6154030"
                                hasText
                            >
                                <PhraseButton
                                    onClick={this.handleEditFileOnClose}
                                    className="secondary-button"
                                />
                            </ModulePhrase>
                            <ModulePhrase
                                defaultText="Lagre"
                                guid="9a6b9172-6bce-43fc-9239-4c3d34953f55"
                                hasText
                            >
                                <PhraseButton
                                    type="submit"
                                    name="submit"
                                    className="primary-button"
                                    style={{ margin: '0em' }}
                                />
                            </ModulePhrase>
                        </Form.Group>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    };

    renderPreviewImage = (filePreview, circular) => {
        const style = circular
            ? {
                  height: '15em',
                  width: '15em',
                  objectFit: 'cover'
              }
            : null;
        return (
            <Image
                size="medium"
                src={filePreview}
                circular={circular}
                style={style}
            />
        );
    };
}

export default FileUploader;

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router';
import Layout from './Layout';
import {
    ModuleWrapper,
    Error,
    Login,
    Logout,
    NoAccess,
    GetFile,
    PrivacyPolicy
} from './app/common';
import CustomProperties from 'react-custom-properties';
import { appOperations } from './duck';
import { authOperations } from './app/common/Auth/duck';
import { Document, Page } from 'react-pdf';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: null
        };
    }

    componentDidMount() {
        const {
            hasLoadedAppContext,
            fetchAppContext,
            config,
            isLoadingConfig,
            fetchPortalConfig,
            isAuthenticated,
            fetchAuthIdent,
            authIdent,
            isLoadingAuthIdent,
            validateAuth,
            isLoggingIn,
            selectedLang,
            hasLoadedGlobalPhrases,
            isLoadingGlobalPhrases,
            fetchGlobalPhrases,
            hasLoadedPortalPhrases,
            isLoadingPortalPhrases,
            fetchPortalPhrases
        } = this.props;
        const params = new URLSearchParams(this.props.location.search);
        this.setState({
            params: params
        });

        if (!config && !isLoadingConfig) {
            fetchPortalConfig();
        }

        if (!hasLoadedGlobalPhrases && !isLoadingGlobalPhrases) {
            fetchGlobalPhrases(selectedLang);
        }

        if (!hasLoadedPortalPhrases && !isLoadingPortalPhrases) {
            fetchPortalPhrases(selectedLang);
        }

        if (!authIdent && !isLoadingAuthIdent) {
            fetchAuthIdent('', false);
        }

        if (config && !isLoadingConfig) {
            this.loadManifest();
            this.setFavicon(_.get(config, 'favicon'));
        }

        if (!isAuthenticated && authIdent && !isLoggingIn) {
            validateAuth(authIdent, params.get('code'));
        }
        if (isAuthenticated && !hasLoadedAppContext) {
            fetchAppContext();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            hasLoadedAppContext,
            fetchAppContext,
            config,
            isLoadingConfig,
            fetchPortalConfig,
            isAuthenticated,
            authIdent,
            isLoggingIn,
            validateAuth,
            selectedLang,
            fetchGlobalPhrases,
            isLoadingGlobalPhrases,
            fetchPortalPhrases,
            isLoadingPortalPhrases
        } = this.props;
        const { params } = this.state;
        if (config !== prevProps.config && !isLoadingConfig) {
            if (config) {
                this.setFavicon(_.get(config, 'favicon'));
            } else {
                fetchPortalConfig();
            }
        }
        if (
            !isAuthenticated &&
            authIdent &&
            !_.isEqual(authIdent, prevProps.authIdent) &&
            !isLoggingIn
        ) {
            validateAuth(authIdent, params.get('code'));
        }
        if (
            isAuthenticated &&
            isAuthenticated !== prevProps.isAuthenticated &&
            !hasLoadedAppContext
        ) {
            fetchAppContext();
        }
        if (selectedLang !== prevProps.selectedLang) {
            if (!isLoadingPortalPhrases) {
                fetchPortalPhrases(selectedLang);
            }
            if (!isLoadingGlobalPhrases) {
                fetchGlobalPhrases(selectedLang);
            }
        }
    }

    setFavicon(src) {
        // Source https://www.jerriepelser.com/blog/change-favicon-application-state/
        if (src) {
            var link = document.createElement('link'),
                oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }
    }

    render() {
        const { isUnauthorized, config } = this.props;
        const theme = _.get(config, 'theme') || '';

        return (
            <CustomProperties properties={theme ? JSON.parse(theme) : {}}>
                <Switch>
                    <Route exact path="/Signin" component={Login} />
                    <Route exact path="/Signout" component={Logout} />
                    <Route exact path="/Error" component={Error} />
                    <Route exact path="/Error" component={Error} />
                    <Route exact path="/NoAccess" component={NoAccess} />
                    <Route exact path="/Privacy" component={PrivacyPolicy} />
                    <PrivateRoute
                        exact
                        path="/Storage/GetFile/:fileId"
                        component={GetFile}
                        isUnauthorized={isUnauthorized}
                        location={this.props.location}
                    />
                    <PrivateRoute
                        exact
                        path="/:moduleName?/:subModuleName?/:foo*"
                        component={ModuleWrapper}
                        layout={Layout}
                        isUnauthorized={isUnauthorized}
                        location={this.props.location}
                    />
                </Switch>
            </CustomProperties>
        );
    }
}

function PrivateRoute({
    layout,
    component,
    isUnauthorized,
    location,
    ...rest
}) {
    if (isUnauthorized) {
        return (
            <Redirect
                to={{
                    pathname: '/signin',
                    state: {
                        returnUrl: location.pathname,
                        search: location.search
                    }
                }}
            />
        );
    }
    if (layout) {
        return (
            <Route
                {...rest}
                render={props =>
                    React.createElement(
                        layout,
                        props,
                        React.createElement(component, props)
                    )
                }
            />
        );
    }
    return (
        <Route
            {...rest}
            render={props => React.createElement(component, props)}
        />
    );
}

const mapStateToProps = state => {
    const {
        isError,
        errorRedirectUri,
        config,
        hasLoadedAppContext,
        isLoading,
        selectedLang,
        isLoadingGlobalPhrases,
        isLoadingPortalPhrases
    } = state.app;
    const {
        isAuthenticated,
        isUnauthorized,
        authIdent,
        isLoadingAuthIdent,
        isLoggingIn
    } = state.auth;
    return {
        isError,
        errorRedirectUri,
        hasLoadedAppContext,
        isAuthenticated,
        isUnauthorized,
        authIdent,
        isLoadingAuthIdent,
        isLoggingIn,
        config,
        isLoading,
        selectedLang,
        isLoadingGlobalPhrases,
        isLoadingPortalPhrases
    };
};

const mapDispatchToProps = dispatch => {
    const fetchAppContext = () => {
        dispatch(appOperations.fetchAppContext());
    };
    const fetchPortalConfig = () => {
        dispatch(appOperations.fetchPortalConfig());
    };
    const fetchAuthIdent = (ident, isPersistent) => {
        return dispatch(authOperations.fetchAuthIdent(ident, isPersistent));
    };
    const validateAuth = (authIdent, code) => {
        dispatch(authOperations.doValidateAuth(authIdent, code));
    };
    const fetchGlobalPhrases = lang => {
        dispatch(appOperations.fetchGlobalPhrases(lang));
    };
    const fetchPortalPhrases = lang => {
        dispatch(appOperations.fetchPortalPhrases(lang));
    };
    return {
        fetchAppContext,
        fetchPortalConfig,
        fetchAuthIdent,
        validateAuth,
        fetchGlobalPhrases,
        fetchPortalPhrases
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import React from 'react';
import { Icon, Header } from 'semantic-ui-react';
import '../phrase.css';

class PhraseHeaderComponent extends React.Component {
    render() {
        const {
            text,
            hasDescription,
            icon,
            size,
            color,
            dividing,
            floated,
            textAlign,
            showEdit,
            className,
            as,
            maxWidth,
            style
        } = this.props;
        return (
            <React.Fragment>
                <Header
                    style={{ ...style, maxWidth: maxWidth }}
                    size={size}
                    as={as}
                    color={color}
                    dividing={dividing}
                    floated={floated}
                    textAlign={textAlign}
                    className={
                        className
                            ? `phrase-header ${className}`
                            : 'phrase-header'
                    }
                >
                    {icon && this.renderIcon()}
                    <Header.Content>
                        {text}
                        {hasDescription && this.renderDescription()}
                    </Header.Content>
                </Header>
                {showEdit && this.renderEditIcon()}
            </React.Fragment>
        );
    }

    renderIcon = () => {
        const { icon } = this.props;
        return <Icon name={icon} />;
    };

    renderDescription = () => {
        const { description } = this.props;
        return <Header.Subheader>{description}</Header.Subheader>;
    };

    renderLabel = () => {
        const { label, showEdit } = this.props;
        return (
            <label>
                {label} {showEdit && this.renderEditIcon()}
            </label>
        );
    };

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseHeaderComponent;

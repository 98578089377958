import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestChemicalAccounts: [],
    receiveChemicalAccounts: ['response'],
    requestChemAccountInfo: [],
    receiveChemAccountInfo: ['response'],
    requestChemicalAccountTabs: [],
    receiveChemicalAccountTabs: ['response'],
    requestOperators: [],
    receiveOperators: ['response'],
    createGenericTab: [],
    createdGenericTab: ['response'],
    deleteGenericTab: [],
    deletedGenericTab: ['response', 'id'],
    saveChemicalDrips: [],
    savedChemicalDrips: ['response'],
    updateChemicalDrips: [],
    updatedChemicalDrips: ['response'],
    requestChemicalAccountTabInfo: [],
    receiveChemicalAccountTabInfo: ['response'],
    phaseTypesRequested: [],
    phaseTypesReceived: ['response'],
    phaseTypesFailed: ['error'],
    updateGenericTab: [],
    updatedGenericTab: ['response'],
    deleteUsageDrips: [],
    deletedUsageDrips: ['response', 'id'],
    requestAllChemicals: [],
    receiveAllChemicals: ['chemicals'],
    requestSelectedChemicalInfo: [],
    receiveSelectedChemicalInfo: ['chemicalInfo'],
    updateGenericTabInfo: [],
    updatedGenericTabInfo: ['response']
});

export { Creators, Types };

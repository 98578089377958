import { connect } from 'react-redux';
import NotificationsComponent from './NotificationsComponent';
import { notificationsOperations } from './duck';
import { withRouter } from 'react-router';

const mapStateToProps = (state, props) => {
    const { notifications, isNotificationsVisible, isLoadingNotifications } = state.notifications;
    return { notifications, isNotificationsVisible, isLoadingNotifications }
};

const mapDispatchToProps = dispatch => {
    const showNotifications = () => {
        dispatch(notificationsOperations.showNotifications());
    };
    const hideNotifications = () => {
        dispatch(notificationsOperations.hideNotifications());
    };
    const fetchNotifications = () => {
        dispatch(notificationsOperations.fetchNotifications());
    }
    const updateReadNotifications = (readNotifications) => {
        dispatch(notificationsOperations.updateReadNotifications(readNotifications));
    }
    return { showNotifications, hideNotifications, fetchNotifications, updateReadNotifications }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent));
import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Segment, Header, Icon, Button, Message } from 'semantic-ui-react';


class ErrorComponent extends React.Component {
    componentDidMount() {

    }

    handleHomeButtonOnClick = (e) => {
        this.props.history.push('/');
    }

    render() {
        const search = new URLSearchParams(this.props.location.search);
        const errorMessage = search.get('errorMessage');
        return (
            <Segment placeholder>
                <Header icon>
                    <Icon name='frown outline' />
                    Something went horribly wrong!
                </Header>
                {errorMessage && this.renderErrorMessage(errorMessage)}
                <Segment.Inline>
                    <Button onClick={this.handleHomeButtonOnClick} icon primary>Go home</Button>
                </Segment.Inline>
            </Segment>
        );
    }

    renderErrorMessage = (errorMessage) => {
        return <Message negative>
                   <Message.Header>Response from server</Message.Header>
                   <p>{errorMessage}</p>
               </Message>;
    }
}

export default withRouter(ErrorComponent);
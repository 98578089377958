import React from 'react';
import { Menu, Icon, Header } from 'semantic-ui-react';
import '../phrase.css';

class PhraseMenuItemComponent extends React.Component {
    render() {
        const {
            showEdit,
            text,
            active,
            as,
            children,
            className,
            color,
            content,
            disabled,
            fitted,
            header,
            icon,
            index,
            link,
            name,
            onClick,
            position
        } = this.props;
        return (
            <React.Fragment>
                <Menu.Item
                    active={active}
                    as={as}
                    children={children}
                    color={color}
                    content={<React.Fragment>
                        {text}
                        {content}
                        </React.Fragment>}
                    disabled={disabled}
                    fitted={fitted}
                    header={header}
                    icon={icon}
                    index={index}
                    link={link}
                    name={name}
                    position="right"
                    onClick={onClick}
                    className={
                        className
                            ? `phrase-menu-item ${className}`
                            : 'phrase-menu-item'
                    }
                >
                    {children}
                </Menu.Item>
                {showEdit && this.renderEditIcon()}
            </React.Fragment>
        );
    }

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon right"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseMenuItemComponent;

import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestAfd: [],
    receiveAfd: ['result'],
    requestAfds: [],
    receiveAfds: ['result'],
    createAfd: [],
    createdAfd: ['result'],
    updateAfd: [],
    updatedAfd: ['result'],
    deleteAfd: [],
    deletedAfd: ['response', 'id'],
    requestAfdInfo: [],
    receiveAfdInfo: ['result'],
    requestAfdTab: [],
    receiveAfdTab: ['result'],
    createAfdTab: [],
    createdAfdTab: ['result'],
    updateAfdTab: [],
    updatedAfdTab: ['result'],
    deleteAfdTab: [],
    deletedAfdTab: ['response', 'afdId', 'tabId'],
    requestOperators: [],
    receiveOperators: ['response'],
    requestSuppliers: [],
    receiveSuppliers: ['response'],
    requestChemicals: [],
    receiveChemicals: ['chemicals'],
    requestChemicalInfo: [],
    receiveChemicalInfo: ['chemicalInfo'],
    requestWellbores: [],
    receiveWellbores: ['response']
});

export { Creators, Types };

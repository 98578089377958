import React from 'react';
import _ from 'lodash';
import {
    Sidebar,
    Segment,
    Responsive
} from 'semantic-ui-react';
import './general.css';

class SidebarWrapper extends React.Component {
    render = () => {
        const { visible, direction, children, style } = this.props;

        return (
            <React.Fragment>
                <Responsive
                minWidth={Responsive.onlyTablet.minWidth}
                fireOnMount
                >
                    <Sidebar
                        as={Segment}
                        animation="overlay"
                        direction={direction}
                        visible={visible}
                        width={direction === "right" ? "very wide" : ""}
                        className="sidebar-wrapper"
                        style={style}
                    >
                        {children && this.renderChildren()}
                    </Sidebar>
                </Responsive>

                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    fireOnMount
                >
                    <Sidebar
                        as={Segment}
                        animation="overlay"
                        direction={direction}
                        visible={visible}
                        className="sidebar-wrapper mobile"
                    >
                        {children && this.renderChildren()}
                    </Sidebar>
                </Responsive>
        </React.Fragment>
        );
    };

    renderChildren = () => {
        const { children } = this.props;
        return children;
    };
}

export default SidebarWrapper;
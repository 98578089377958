import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Responsive } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { Sidebar, NavBar, Loader, Notifications } from './app/common';
import { appOperations } from './duck';
import { sidebarOperations } from './app/common/Sidebar/duck';
import { DeviceTypes } from './app/utility/deviceTypes';
import './styles/css/variables.css';
import './styles/semantic/semantic.css';
import './styles/css/layout.css';
import './styles/css/styles.css';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.domRef = React.createRef();
        this.domRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', _.debounce(this.setDeviceType, 150));
        this.setDeviceType();
    }

    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            _.debounce(this.setDeviceType, 150)
        );
    }

    setDeviceType = () => {
        const { deviceType } = this.props;
        const { innerWidth } = window;

        if (
            innerWidth <= Responsive.onlyMobile.maxWidth &&
            deviceType !== DeviceTypes.mobile
        ) {
            this.props.setDeviceType(DeviceTypes.mobile);
            this.props.handleHideSidebar();
        }
        if (
            innerWidth >= Responsive.onlyTablet.minWidth &&
            innerWidth <= Responsive.onlyTablet.maxWidth &&
            deviceType !== DeviceTypes.tablet
        ) {
            if (deviceType === DeviceTypes.mobile) {
                this.props.handleShowSidebar();
            }
            this.props.setDeviceType(DeviceTypes.tablet);
        }
        if (
            innerWidth >= Responsive.onlyComputer.minWidth &&
            innerWidth <= Responsive.onlyComputer.maxWidth &&
            deviceType !== DeviceTypes.computer
        ) {
            if (deviceType === DeviceTypes.mobile) {
                this.props.handleShowSidebar();
            }
            this.props.setDeviceType(DeviceTypes.computer);
        }
        if (
            innerWidth >= Responsive.onlyLargeScreen.minWidth &&
            innerWidth <= Responsive.onlyLargeScreen.maxWidth &&
            deviceType !== DeviceTypes.largeScreen
        ) {
            if (deviceType === DeviceTypes.mobile) {
                this.props.handleShowSidebar();
            }
            this.props.setDeviceType(DeviceTypes.largeScreen);
        }
        if (
            innerWidth >= Responsive.onlyWidescreen.minWidth &&
            innerWidth <= Responsive.onlyWidescreen.maxWidth &&
            deviceType !== DeviceTypes.widescreen
        ) {
            if (deviceType === DeviceTypes.mobile) {
                this.props.handleShowSidebar();
            }
            this.props.setDeviceType(DeviceTypes.widescreen);
        }
    };

    render() {
        return (
            <React.Fragment>
                <Responsive
                    minWidth={Responsive.onlyTablet.minWidth}
                    className="site-container"
                    id="site-container"
                    fireOnMount
                >
                    {this.renderDesktopWrapper()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    className="site-container"
                    id="site-container"
                    fireOnMount
                >
                    {this.renderMobileWrapper()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderDesktopWrapper = () => {
        const {
            isLoading,
            hasLoadedAppContext,
            isLoadingGlobalPhrases,
            hasLoadedGlobalPhrases,
            isLoadingPortalPhrases,
            hasLoadedPortalPhrases,
            children
        } = this.props;
        const showLoader =
            isLoading ||
            !hasLoadedAppContext ||
            isLoadingGlobalPhrases ||
            !hasLoadedGlobalPhrases ||
            isLoadingPortalPhrases ||
            !hasLoadedPortalPhrases;
        if (showLoader) {
            return this.renderLoader();
        }
        return (
            <React.Fragment>
                <Sidebar />
                <div className="main-container">
                    <NavBar />
                    {children}
                </div>
            </React.Fragment>
        );
    };

    renderMobileWrapper = () => {
        const {
            isSidebarVisible,
            isNotificationsVisible,
            isLoading,
            hasLoadedAppContext,
            isLoadingGlobalPhrases,
            hasLoadedGlobalPhrases,
            isLoadingPortalPhrases,
            hasLoadedPortalPhrases,
            children
        } = this.props;
        const showLoader =
            isLoading ||
            !hasLoadedAppContext ||
            isLoadingGlobalPhrases ||
            !hasLoadedGlobalPhrases ||
            isLoadingPortalPhrases ||
            !hasLoadedPortalPhrases;
        if (showLoader) {
            return this.renderLoader();
        }
        return (
            <React.Fragment>
                <Sidebar />
                <Notifications />
                <div className="main-container">
                    <NavBar />
                    {children}
                    <div
                        className={
                            isSidebarVisible || isNotificationsVisible
                                ? 'page-dimmer-overlay active'
                                : 'page-dimmer-overlay inactive'
                        }
                    />
                </div>
            </React.Fragment>
        );
    };

    renderLoader = () => {
        return <Loader />;
    };
}

const mapStateToProps = (state, props) => {
    const {
        appContext,
        isLoading,
        hasLoadedAppContext,
        isLoadingGlobalPhrases,
        hasLoadedGlobalPhrases,
        isLoadingPortalPhrases,
        hasLoadedPortalPhrases,
        isLoadingModulePhrases,
        hasLoadedModulePhrases,
        deviceType,
        selectedLang
    } = state.app;
    const { authIdent } = state.auth;
    const { isSidebarVisible } = state.sidebar;
    const { isNotificationsVisible } = state.notifications;
    return {
        appContext,
        isLoading,
        hasLoadedAppContext,
        isLoadingGlobalPhrases,
        hasLoadedGlobalPhrases,
        isLoadingPortalPhrases,
        hasLoadedPortalPhrases,
        isLoadingModulePhrases,
        hasLoadedModulePhrases,
        deviceType,
        selectedLang,
        isSidebarVisible,
        isNotificationsVisible,
        authIdent
    };
};

const mapDispatchToProps = dispatch => {
    const fetchGlobalPhrases = lang => {
        dispatch(appOperations.fetchGlobalPhrases(lang));
    };
    const fetchPortalPhrases = lang => {
        dispatch(appOperations.fetchPortalPhrases(lang));
    };
    const setDeviceType = deviceType => {
        dispatch(appOperations.setDeviceType(deviceType));
    };
    const handleShowSidebar = () => {
        dispatch(sidebarOperations.handleShowSidebar());
    };
    const handleHideSidebar = () => {
        dispatch(sidebarOperations.handleHideSidebar());
    };
    return {
        fetchGlobalPhrases,
        fetchPortalPhrases,
        setDeviceType,
        handleShowSidebar,
        handleHideSidebar
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Layout)
);

import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    requestChemicals: [],
    receiveChemicals: ['chemicals'],
    createChemical: [],
    createdChemical: ['result', 'isError'],
    requestChemicalInfo: [],
    receiveChemicalInfo: ['chemicalInfo'],
    requestChemicalVersionInfo: [],
    receiveChemicalVersionInfo: ['chemicalVersionInfo'],
    createNewChemicalVersion: [],
    createdNewChemicalVersion: ['result', 'isError'],
    deleteChemical: [],
    deletedChemical: ['result', 'isError'],
    approveChemical: [],
    approvedChemical: ['result', 'isError'],
    requestDropdownInfo: [],
    receiveDropdownInfo: ['dropdownInfo'],
});

export { Creators, Types };

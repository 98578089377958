import React from 'react';
import { Icon, Breadcrumb } from 'semantic-ui-react';
import '../phrase.css';

class PhraseBreadcrumbComponent extends React.Component {
    render() {
        const {
            text,
            active,
            as,
            children,
            className,
            content,
            href,
            link,
            onClick,
            showEdit
        } = this.props;
        return (
            <React.Fragment>
                <Breadcrumb.Section
                    active={active}
                    as={as}
                    children={children}
                    className={className ? `phrase-header ${className}` : 'phrase-header'}
                    content={content}
                    href={href}
                    link={link}
                    onClick={onClick}
                >
                    {text}
                </Breadcrumb.Section>
                {showEdit && this.renderEditIcon()}
            </React.Fragment>
        );
    }

    renderEditIcon = () => {
        return (
            <Icon
                name="edit"
                className="phrase-edit-icon"
                onClick={this.props.handleOpenEdit}
            />
        );
    };
}

export default PhraseBreadcrumbComponent;
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NavBarComponent from './NavBarComponent';
import { navBarOperations } from './duck';
import { sidebarOperations } from '../Sidebar/duck';
import { notificationsOperations } from '../Notifications/duck';
import { appOperations } from '../../../duck';

const mapStateToProps = (state, props) => {
    const {
        needsQa,
        isQaMode,
        qaAcceptedValues,
        qaRunOnSave,
        isSavingQa,
        isErrorSavingQa
    } = state.navBar;
    const {
        config,
        isLoading,
        modules,
        selectedModule,
        selectedLang,
        menu,
        deviceType,
        navigationPath
    } = state.app;
    const { authIdent } = state.auth;
    const { myProfile } = state.myPage;
    const {
        notifications,
        isNotificationsVisible,
        isLoadingNotifications
    } = state.notifications;
    const { isSidebarVisible } = state.sidebar;
    return {
        needsQa,
        isQaMode,
        qaAcceptedValues,
        qaRunOnSave,
        config,
        isLoading,
        modules,
        selectedModule,
        isSidebarVisible,
        notifications,
        isLoadingNotifications,
        selectedLang,
        authIdent,
        menu,
        deviceType,
        navigationPath,
        isNotificationsVisible,
        myProfile,
        isSavingQa,
        isErrorSavingQa
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const handleShowSidebar = () => {
        dispatch(sidebarOperations.handleShowSidebar());
    };
    const handleHideSidebar = () => {
        dispatch(sidebarOperations.handleHideSidebar());
    };
    const hideNotifications = () => {
        dispatch(notificationsOperations.hideNotifications());
    };
    const setSelectedLang = lang => {
        dispatch(appOperations.setSelectedLang(lang));
    };

    const handleStartQa = () => {
        dispatch(navBarOperations.startQaMode());
    };
    const handleStopQa = () => {
        dispatch(navBarOperations.stopQaMode());
    };
    const handleSaveQa = commands => {
        dispatch(navBarOperations.saveQa(commands));
    };

    return {
        handleShowSidebar,
        handleHideSidebar,
        setSelectedLang,
        hideNotifications,
        handleStartQa,
        handleStopQa,
        handleSaveQa
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NavBarComponent)
);

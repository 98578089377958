import React from 'react';
import _ from 'lodash';
import { Form, Popup, Icon, Button, Header, Message } from 'semantic-ui-react';

const textType = '0';
const labelType = '1';
const descriptionType = '2';
const placeholderType = '3';

const errorText = 'Error';
const textLabel = 'Text';
const labelLabel = 'Label';
const placeholderLabel = 'Placeholder';
const descriptionLabel = 'Description';

class PhraseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditOpen: false,
            isLoading: false,
            isError: false,
            errorMessages: [],
            text: null,
            label: null,
            placeholder: null,
            description: null,
            updatedText: null,
            updatedLabel: null,
            updatedPlaceholder: null,
            updatedDescription: null,
            textLabel: textLabel,
            labelLabel: labelLabel,
            placeholderLabel: placeholderLabel,
            descriptionLabel: descriptionLabel,
            isTextError: false,
            isLabelError: false,
            isPlaceholderError: false,
            isDescriptionError: false
        };
    }

    componentDidMount() {
        const {
            hasText,
            hasLabel,
            hasPlaceholder,
            hasDescription
        } = this.props;
        if (hasText) {
            this.setText();
        }
        if (hasLabel) {
            this.setLabel();
        }
        if (hasPlaceholder) {
            this.setPlaceholder();
        }
        if (hasDescription) {
            this.setDescription();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            phrases,
            guid,
            hasText,
            defaultText,
            hasLabel,
            hasPlaceholder,
            hasDescription,
            defaultDescription,
            defaultLabel,
            defaultPlaceholder
        } = this.props;
        if (
            phrases !== prevProps.phrases ||
            guid !== prevProps.guid ||
            hasText !== prevProps.hasText ||
            hasLabel !== prevProps.hasLabel ||
            hasPlaceholder !== prevProps.hasPlaceholder ||
            hasDescription !== prevProps.hasDescription ||
            defaultText !== prevProps.defaultText ||
            defaultDescription !== prevProps.defaultDescription ||
            defaultLabel !== prevProps.defaultLabel ||
            defaultPlaceholder !== prevProps.defaultPlaceholder
        ) {
            if (hasText) {
                this.setText();
            } else {
                this.setState({
                    text: null,
                    updatedText: null
                });
            }
            if (hasLabel) {
                this.setLabel();
            } else {
                this.setState({
                    label: null,
                    updatedLabel: null
                });
            }
            if (hasPlaceholder) {
                this.setPlaceholder();
            } else {
                this.setState({
                    placeholder: null,
                    updatedPlaceholder: null
                });
            }
            if (hasDescription) {
                this.setDescription();
            } else {
                this.setState({
                    description: null,
                    updatedDescription: null
                });
            }
        }
    }

    setText = () => {
        const { defaultText, selectedLang, showEdit } = this.props;
        var text;
        var phrase = this.getPhrase(textType);
        if (
            !phrase ||
            !phrase.value ||
            _.toLower(phrase.lang) !== _.toLower(selectedLang)
        ) {
            if (showEdit) {
                this.setState({ isTextError: true });
            }
            text = defaultText || null;
        } else {
            text = phrase.value;
        }
        this.setState({
            text: text,
            updatedText: text
        });
    };

    setLabel = () => {
        const { defaultLabel, selectedLang, showEdit } = this.props;
        var label;
        var phrase = this.getPhrase(labelType);
        if (
            !phrase ||
            !phrase.value ||
            _.toLower(phrase.lang) !== _.toLower(selectedLang)
        ) {
            if (showEdit) {
                this.setState({ isLabelError: true });
            }
            label = defaultLabel || null;
        } else {
            label = phrase.value;
        }
        this.setState({
            label: label,
            updatedLabel: label
        });
    };

    setDescription = () => {
        const { defaultDescription, selectedLang, showEdit } = this.props;
        var description;
        var phrase = this.getPhrase(descriptionType);
        if (
            !phrase ||
            !phrase.value ||
            _.toLower(phrase.lang) !== _.toLower(selectedLang)
        ) {
            if (showEdit) {
                this.setState({ isDescriptionError: true });
            }
            description = defaultDescription || null;
        } else {
            description = phrase.value;
        }
        this.setState({
            description: description,
            updatedDescription: description
        });
    };

    setPlaceholder = () => {
        const { defaultPlaceholder, selectedLang, showEdit } = this.props;
        var placeholder;
        var phrase = this.getPhrase(placeholderType);
        if (
            !phrase ||
            !phrase.value ||
            _.toLower(phrase.lang) !== _.toLower(selectedLang)
        ) {
            if (showEdit) {
                this.setState({ isPlaceholderError: true });
            }
            placeholder = defaultPlaceholder || null;
        } else {
            placeholder = phrase.value;
        }
        this.setState({
            placeholder: placeholder,
            updatedPlaceholder: placeholder
        });
    };

    getPhrase = type => {
        const { guid, phrases } = this.props;
        if (!guid) return null;
        return _.find(phrases, phrase => {
            return (
                _.toLower(phrase.guid) === _.toLower(guid) &&
                _.toLower(phrase.type) === _.toLower(type)
            );
        });
    };

    getGlobalPhraseText = guid => {
        const { globalPhrases } = this.props;
        if (!guid) return null;
        return _.get(
            _.find(globalPhrases, phrase => {
                return (
                    _.toLower(phrase.guid) === _.toLower(guid) &&
                    _.toLower(phrase.type) === _.toLower(textType)
                );
            }),
            'value'
        );
    };

    handleOpenEdit = () => {
        this.setState({ isEditOpen: true });
    };

    handleOnSubmit = () => {
        const { guid, selectedLang, onSavePhrases } = this.props;
        const {
            text,
            label,
            placeholder,
            description,
            updatedText,
            updatedLabel,
            updatedPlaceholder,
            updatedDescription,
            errorMessages
        } = this.state;

        var phrases = [];
        console.log('TEXT', text, updatedText);
        if (text !== updatedText) {
            const textPhrase = {
                guid: guid,
                value: updatedText,
                lang: selectedLang,
                type: textType
            };
            phrases.push(textPhrase);
        }

        if (label !== updatedLabel) {
            const labelPhrase = {
                guid: guid,
                value: updatedLabel,
                lang: selectedLang,
                type: labelType
            };
            phrases.push(labelPhrase);
        }

        if (placeholder !== updatedPlaceholder) {
            const placeholderPhrase = {
                guid: guid,
                value: updatedPlaceholder,
                lang: selectedLang,
                type: placeholderType
            };
            phrases.push(placeholderPhrase);
        }

        if (description !== updatedDescription) {
            const descriptionPhrase = {
                guid: guid,
                value: updatedDescription,
                lang: selectedLang,
                type: descriptionType
            };
            phrases.push(descriptionPhrase);
        }

        if (!_.isEmpty(phrases)) {
            //this.setState({ isLoading: true });
            onSavePhrases(phrases);
            //    .then(result => {
            //        if (!result || _.some(result, ['isError', true])) {
            //            console.log(
            //                'SAVE_ERROR',
            //                _.map(result, 'errorMessage')
            //            );
            //            this.setState({
            //                isLoading: false,
            //                isError: true,
            //                errorMessages: _.concat(
            //                    errorMessages,
            //                    _.map(result, 'errorMessage')
            //                )
            //            });
            //        } else {
            //            this.setState({
            //                isLoading: false,
            //                isEditOpen: false,
            //                isError: false,
            //                errorMessages: [],
            //                text: updatedText,
            //                label: updatedLabel,
            //                placeholder: updatedPlaceholder,
            //                description: updatedDescription
            //            });
            //        }
            //        console.log('ERROR_MESSAGES', this.state.errorMessages);
            //    })
            //    .catch(ex => {
            //        this.setState({
            //            isLoading: false,
            //            isError: true,
            //            errorMessages: _.concat(errorMessages, [
            //                _.get(ex, 'message')
            //            ])
            //        });
            //    });
        }
    };

    onCancelEdit = () => {
        this.setText();
        this.setLabel();
        this.setPlaceholder();
        this.setDescription();
        this.setState({ isEditOpen: false });
    };

    handleOnChange = e => {
        const { name, value } = e.target;
        if (name === 'updatedText') {
            this.setState({ isTextError: !value });
        } else if (name === 'updatedLabel') {
            this.setState({ isLabelError: !value });
        } else if (name === 'updatedPlaceholder') {
            this.setState({ isPlaceholderError: !value });
        } else if (name === 'updatedDescription') {
            this.setState({ isDescriptionError: !value });
        }
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { children, showEdit, ...props } = this.props;
        const child = React.cloneElement(children, {
            handleOpenEdit: this.handleOpenEdit,
            text: this.state.text,
            label: this.state.label,
            placeholder: this.state.placeholder,
            description: this.state.description,
            showEdit: showEdit,
            ...props
        });
        if (showEdit) {
            return this.renderEditPopup(child);
        }
        return child;
    }

    renderEditPopup = child => {
        const {
            hasText,
            hasLabel,
            hasPlaceholder,
            hasDescription,
            isSaving
        } = this.props;
        const { isEditOpen, isLoading, errorMessages } = this.state;
        const errors = _.map(errorMessages, this.renderErrorMessage);
        return (
            <Popup open={isEditOpen} trigger={child} flowing hoverable>
                <Header as="h2">
                    <Icon name="edit" />
                    <Header.Content>
                        {this.getGlobalPhraseText(
                            'bad0968d-519c-4ec6-8103-94c169d54c7b'
                        ) || 'Edit text'}
                    </Header.Content>
                </Header>
                <Form
                    onSubmit={this.handleOnSubmit}
                    loading={isLoading || isSaving}
                    error={this.state.isError}
                >
                    {hasText && this.renderTextEdit()}
                    {hasLabel && this.renderLabelEdit()}
                    {hasPlaceholder && this.renderPlaceholderEdit()}
                    {hasDescription && this.renderDescriptionEdit()}
                    <Button type="submit" floated="right" primary>
                        {this.getGlobalPhraseText(
                            'ffdb951d-04c0-4284-8987-40ed2e78cad1'
                        ) || 'Save'}
                    </Button>
                    <Button
                        type="button"
                        floated="right"
                        onClick={this.onCancelEdit}
                        disabled={isSaving}
                    >
                        {this.getGlobalPhraseText(
                            '032c4653-6582-4c54-9010-c61ceab48d9f'
                        ) || 'Cancel'}
                    </Button>
                    {errors}
                </Form>
            </Popup>
        );
    };

    renderErrorMessage = message => {
        return (
            <Message
                error
                header={
                    this.getGlobalPhraseText(
                        '87b17d4e-df46-4618-a914-a13619ace286'
                    ) || errorText
                }
                content={message}
            />
        );
    };

    renderTextEdit = () => {
        const { isTextError } = this.state;
        return (
            <Form.Field required>
                <label>
                    {this.getGlobalPhraseText(
                        'bba5510a-4ae5-487e-8014-c411d4a19021'
                    ) || textLabel}
                </label>
                <Form.Input
                    name="updatedText"
                    value={this.state.updatedText}
                    onChange={this.handleOnChange}
                    error={isTextError}
                />
            </Form.Field>
        );
    };

    renderLabelEdit = () => {
        const { isLabelError } = this.state;
        return (
            <Form.Field required>
                <label>
                    {this.getGlobalPhraseText(
                        'ddbf7fa2-72fb-477a-9c8b-37ade08bf0d0'
                    ) || labelLabel}
                </label>
                <Form.Input
                    name="updatedLabel"
                    value={this.state.updatedLabel}
                    onChange={this.handleOnChange}
                    error={isLabelError}
                />
            </Form.Field>
        );
    };

    renderPlaceholderEdit = () => {
        const { isPlaceholderError } = this.state;
        return (
            <Form.Field required>
                <label>
                    {this.getGlobalPhraseText(
                        'e8cc5031-de33-4ef3-a0b2-5844f277e908'
                    ) || placeholderLabel}
                </label>
                <Form.Input
                    name="updatedPlaceholder"
                    value={this.state.updatedPlaceholder}
                    onChange={this.handleOnChange}
                    error={isPlaceholderError}
                />
            </Form.Field>
        );
    };

    renderDescriptionEdit = () => {
        const { isDescriptionError } = this.state;
        return (
            <Form.Field required>
                <label>
                    {this.getGlobalPhraseText(
                        '302bf624-f6f9-472a-aae9-c6be91a249b6'
                    ) || descriptionLabel}
                </label>
                <Form.Input
                    name="updatedDescription"
                    value={this.state.updatedDescription}
                    onChange={this.handleOnChange}
                    error={isDescriptionError}
                />
            </Form.Field>
        );
    };
}

export default PhraseComponent;

// @app/common/sidebar/duck/reducers.js
import { createReducer } from 'reduxsauce'
import { Types } from './actions';
import { Menu } from './classes';

export const INITIAL_STATE = {
    menu: new Menu(),
    isSidebarVisible: true,
    isLoadingMenu: false
}

export const requestMenu = (state = INITIAL_STATE, action) => {
    return { ...state, isLoadingMenu: true }
}

export const receiveMenu = (state = INITIAL_STATE, action) => {
    const { menu } = action;
    return { ...state, menu: menu, isLoadingMenu: false }
}

export const showSidebar = (state = INITIAL_STATE, action) => {
    return { ...state, isSidebarVisible: true }
}

export const hideSidebar = (state = INITIAL_STATE, action) => {
    return { ...state, isSidebarVisible: false }
}

export const HANDLERS = {
    [Types.REQUEST_MENU]: requestMenu,
    [Types.RECEIVE_MENU]: receiveMenu,
    [Types.SHOW_SIDEBAR]: showSidebar,
    [Types.HIDE_SIDEBAR]: hideSidebar
}

export default createReducer(INITIAL_STATE, HANDLERS);
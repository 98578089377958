import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appOperations } from '../../../../duck';
import PhraseComponent from './PhraseComponent';

class GlobalPhraseComponent extends React.Component {
    render() {
        const { globalPhrases, isGlobalPhraseAdmin, isEditable, isSavingGlobalPhrases } = this.props;
        return (
            <PhraseComponent
                phrases={globalPhrases}
                globalPhrases={globalPhrases}
                showEdit={isEditable && isGlobalPhraseAdmin}
                onSavePhrases={this.handleOnSavePhrases}
                isSaving={isSavingGlobalPhrases}
                {...this.props}
            />
        );
    }

    handleOnSavePhrases = phrases => {
        const { selectedLang } = this.props;
        return this.props.saveGlobalPhrases(selectedLang, phrases);
    };
}

const mapStateToProps = (state, props) => {
    const isGlobalPhraseAdmin = false;
    const { selectedLang, globalPhrases, isSavingGlobalPhrases } = state.app;
    return { selectedLang, globalPhrases, isGlobalPhraseAdmin, isSavingGlobalPhrases }
};

const mapDispatchToProps = dispatch => {
    const saveGlobalPhrases = (lang, phrases) => {
        return dispatch(
            appOperations.saveGlobalPhrases(lang, phrases)
        );
    };
    return { saveGlobalPhrases };
};

GlobalPhraseComponent.defaultProps = {
    isEditable: true
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalPhraseComponent);

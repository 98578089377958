import React from 'react';
import _ from 'lodash';
import {
    Menu,
    Image,
    Responsive,
    Icon,
    Input,
    Label,
    Loader,
    Accordion,
    List,
    Flag,
    Breadcrumb,
    Popup
} from 'semantic-ui-react';
import {
    PortalPhrase,
    PhraseMenuItem,
    PhraseBreadcrumb,
    PhraseHeader
} from '../../components';
import { DeviceTypes } from '../../utility/deviceTypes';
import './navbar.css';
import { QaSection } from './components';

class NavBarComponent extends React.Component {
    _myPageModuleName = 'MyPage';
    _languageToflag = [
        { flag: 'no', value: 'nb', text: 'Norwegian' },
        { flag: 'gb', value: 'en', text: 'English' }
    ];

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            ident: null,
            hubConnection: null,
            selectedLang: null,
            dropUpOpen: false,
            showQaReport: false
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        const { selectedLang, authIdent } = this.props;

        this.setState({
            selectedLang: selectedLang,
            name: _.get(authIdent, 'name'),
            ident: _.get(authIdent, 'ident')
        });
        window.addEventListener('keydown', this.handleKeydown);
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        window.removeEventListener('keydown', this.handleKeydown);
    };

    componentDidUpdate = prevProps => {
        const { selectedLang } = this.props;
        if (selectedLang !== prevProps.selectedLang) {
            this.setState({ selectedLang: selectedLang });
        }
    };

    handleToggleSidebar = () => {
        if (this.props.isSidebarVisible) {
            this.props.handleHideSidebar();
        } else {
            this.props.handleShowSidebar();
        }
    };

    handleAvatarOnClick = () => {
        const { modules } = this.props;
        const { moduleName } = this.props.match.params;
        if (_.toLower(moduleName) !== _.toLower(this._myPageModuleName)) {
            const module = _.find(modules, m => {
                return _.toLower(m.name) === _.toLower(this._myPageModuleName);
            });
            if (!_.isEmpty(_.get(module, 'menu'))) {
                const defaultSubModuleName =
                    _.get(module, 'config.defaultSubModule') ||
                    _.get(module, 'menu[0].value');
                this.props.history.push(
                    `/${module.name}/${defaultSubModuleName}`
                );
            } else {
                this.props.history.push(`/${module.name}`);
            }
        }
    };

    handleSignOutOnClick = () => {
        this.props.history.push('/signout');
    };

    handleMyPageOnClick = () => {
        this.props.history.push(`/${this._myPageModuleName}`);
    };

    handleLanguageOnChange = (e, data) => {
        const { setSelectedLang } = this.props;
        this.setState({ selectedLang: data.value });
        setSelectedLang(data.value);
    };

    handleMenuItemClick = newModuleName => {
        const { modules, deviceType } = this.props;
        const { moduleName, subModuleName, foo } = this.props.match.params;
        const module = _.find(modules, m => {
            return _.toLower(m.name) === _.toLower(newModuleName);
        });
        const defaultSubModuleName =
            _.get(module, 'config.defaultSubModule') ||
            _.get(module, 'menu[0].value');
        if (!_.isEmpty(_.get(module, 'menu'))) {
            if (
                _.toLower(newModuleName) !== _.toLower(moduleName) ||
                (subModuleName &&
                    !_.some(
                        module.menu,
                        m => _.toLower(m.name) === _.toLower(subModuleName)
                    ))
            ) {
                this.props.history.push(
                    `/${newModuleName}/${defaultSubModuleName}`
                );
            }
        } else if (
            _.toLower(newModuleName) !== _.toLower(moduleName) ||
            (subModuleName && subModuleName !== defaultSubModuleName)
        ) {
            this.props.history.push(`/${newModuleName}`);
        } else if (foo) {
            if (defaultSubModuleName) {
                this.props.history.push(
                    `/${newModuleName}/${defaultSubModuleName}`
                );
            } else {
                this.props.history.push(`/${newModuleName}`);
            }
        }
        if (deviceType === DeviceTypes.mobile) {
            this.props.handleHideSidebar();
        }
    };

    handleClickSaveQa = () => {
        const { qaRunOnSave, handleSaveQa, handleStopQa } = this.props;
        handleSaveQa(qaRunOnSave);
        handleStopQa();
    };

    handleKeydown = event => {
        const { handleStartQa, isQaMode, authIdent } = this.props;
        const { showQaReport } = this.state;
        const { key, ctrlKey } = event;
        if (ctrlKey && _.get(authIdent, 'portalId') === '3') {
            if (key === 's' || key === 'S') {
                if (isQaMode) {
                    if (showQaReport) {
                        handleStartQa();
                        this.setState({ showQaReport: false });
                    } else {
                        this.setState({ showQaReport: true });
                    }
                }
                event.preventDefault();
            } else if (key === 'q' || key === 'Q') {
                handleStartQa();
                event.preventDefault();
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <Responsive
                    minWidth={Responsive.onlyTablet.minWidth}
                    className="navbar-container"
                    fireOnMount
                >
                    {this.renderDesktop()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    className="navbar-container mobile"
                    fireOnMount
                >
                    {this.renderMobileNavBar()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderDesktop = () => {
        const {
            selectedModule,
            authIdent,
            needsQa,
            isQaMode,
            qaAcceptedValues,
            handleStartQa,
            handleStopQa,
            isSavingQa,
            isErrorSavingQa
        } = this.props;
        const { showQaReport } = this.state;
        //const supportedLangs = _.get(config, 'supportedLangs');
        return (
            <React.Fragment>
                <div style={{ flex: 0, marginLeft: '1.5em' }}>
                    <Icon name="bars" link onClick={this.handleToggleSidebar} />
                </div>
                <div style={{ flex: 1, marginLeft: '1.5em' }}>
                    <PortalPhrase
                        defaultText={_.get(selectedModule, 'name')}
                        guid={_.get(selectedModule, 'titleGuid')}
                        hasText={true}
                    >
                        <PhraseHeader className="middle" size="small" />
                    </PortalPhrase>
                </div>
                <div className="navbar-icons-container">
                    {_.get(authIdent, 'portalId') === '3' && (
                        <QaSection
                            {...{
                                needsQa,
                                isQaMode,
                                qaAcceptedValues,
                                handleStartQa,
                                handleStopQa,
                                showQaReport,
                                isSavingQa,
                                isErrorSavingQa
                            }}
                            handleSaveQa={this.handleClickSaveQa}
                            approverName={_.get(authIdent, 'name')}
                            setShowQaReport={val =>
                                this.setState({ showQaReport: val })
                            }
                        />
                    )}
                    <div
                        style={{
                            flex: 0,
                            marginLeft: '1.5em',
                            marginRight: '1.5em'
                        }}
                    >
                        <Icon name="bell" link />
                    </div>
                    <div
                        style={{
                            flex: 0,
                            marginLeft: '1.5em',
                            marginRight: '1.5em'
                        }}
                    >
                        <Icon name="search" link />
                    </div>
                    <div
                        style={{
                            flex: 0,
                            marginLeft: '1.5em',
                            marginRight: '1.5em'
                        }}
                    >
                        {this.renderAvatar()}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderAvatar = () => {
        const { authIdent } = this.props;
        var name = _.get(authIdent, 'name');
        return (
            <Popup
                trigger={
                    <Label
                        className="navbar-avatar"
                        circular
                        size="huge"
                        as="a"
                    >
                        {name ? name.charAt(0) : ''}
                    </Label>
                }
                content={this.renderAvatarPopupContent()}
                on="click"
                basic
                hoverable
            />
        );
    };

    renderAvatarPopupContent = () => {
        return (
            <List divided relaxed>
                <List.Item>
                    <List.Icon
                        name="user"
                        size="large"
                        verticalAlign="middle"
                    />
                    <List.Content>
                        <List.Header as="a" onClick={this.handleMyPageOnClick}>
                            Min side
                        </List.Header>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon
                        name="log out"
                        size="large"
                        verticalAlign="middle"
                    />
                    <List.Content>
                        <List.Header as="a" onClick={this.handleSignOutOnClick}>
                            Logg ut
                        </List.Header>
                    </List.Content>
                </List.Item>
            </List>
        );
    };

    renderMobileNavBar = () => {
        const { menu } = this.props;
        return <React.Fragment>{menu && this.renderDockMenu()}</React.Fragment>;
    };

    renderDockMenu = () => {
        const { selectedModule, menu } = this.props;
        const length = _.get(menu, 'length') > 4 ? 4 : _.get(menu, 'length');
        return (
            <Menu borderless fixed="bottom" className="navbar-dock">
                {_.map(_.take(menu, 3), (item, i) => (
                    <div key={i} style={{ width: 100 / length + '%' }}>
                        <PortalPhrase
                            defaultText={item.name}
                            guid={item.phraseGuid}
                            hasText={true}
                            className="dock-item"
                        >
                            <PhraseMenuItem
                                icon={
                                    <Icon
                                        name={item.icon}
                                        className="dock-item-icon"
                                        size="large"
                                    />
                                }
                                active={
                                    _.toLower(_.get(selectedModule, 'name')) ===
                                    _.toLower(item.value)
                                }
                                onClick={() =>
                                    this.handleMenuItemClick(item.value)
                                }
                            />
                        </PortalPhrase>
                    </div>
                ))}
                {length === 4 && (
                    <div style={{ width: 25 + '%' }}>
                        <PortalPhrase
                            defaultText={'More'}
                            guid={'d4167d8b-8df6-48c1-9973-0c06b8a0e003'}
                            hasText={true}
                            className="dock-item"
                        >
                            <PhraseMenuItem
                                icon={
                                    <Icon
                                        name="sidebar"
                                        className="dock-item-icon"
                                        size="large"
                                    />
                                }
                                active={_.some(
                                    _.drop(menu, 3),
                                    modul =>
                                        _.toLower(
                                            _.get(selectedModule, 'name')
                                        ) === _.toLower(modul.value)
                                )}
                                onClick={this.handleToggleSidebar}
                            />
                        </PortalPhrase>
                    </div>
                )}
            </Menu>
        );
    };

    renderLogo = () => {
        const { config } = this.props;
        return (
            <Menu.Item>
                <Image
                    src="cms.png"
                    className="sidebar-logo"
                    verticalAlign="middle"
                />
            </Menu.Item>
        );
    };

    renderSearch = () => {
        return (
            <Menu.Item className="navbar-menu-item">
                <Input icon="search" placeholder="Search..." />
            </Menu.Item>
        );
    };

    renderSelectedLanguage = () => {
        const { selectedLang } = this.state;
        const { config } = this.props;
        const supportedLangs = _.get(config, 'supportedLangs');
        const lang = _.find(supportedLangs, l => {
            return l === selectedLang;
        });
        if (!lang) return <Icon loading name="spinner" />;
        const flag = _.find(
            this._languageToflag,
            flag => flag.value === selectedLang
        );
        return (
            <React.Fragment>
                <Flag name={flag.flag} /> {flag.value}
            </React.Fragment>
        );
    };

    renderNotificationsIcon = () => {
        const { notifications, isLoadingNotifications } = this.props;

        const newCount = _.size(
            _.filter(notifications, n => {
                return !n.isRead;
            })
        );

        return (
            <Menu.Item className="navbar-menu-item">
                {(isLoadingNotifications && <Loader active size="mini" />) || (
                    <Icon
                        name="bell"
                        size="large"
                        style={{ marginLeft: '0.35em' }}
                    />
                )}
                {!isLoadingNotifications &&
                    newCount > 0 &&
                    this.renderNewNotificationsCount(newCount)}
            </Menu.Item>
        );
    };

    renderNewNotificationsCount = newCount => {
        return (
            <Label
                color="red"
                circular
                floating
                size="mini"
                style={{ top: '0.40rem', left: '65%' }}
            >
                {newCount}
            </Label>
        );
    };

    renderNotification = (notification, key) => {
        const { activeNotification } = this.state;
        return (
            <React.Fragment key={key}>
                <Accordion.Title
                    active={activeNotification === key}
                    index={key}
                    onClick={this.handleNotificationOnClick}
                >
                    <Icon name="dropdown" />
                    {notification.subject || 'Notification'}
                    <Icon
                        link
                        name="close"
                        onClick={() =>
                            this.handleNotificationCloseOnClick(notification)
                        }
                        style={{ float: 'right' }}
                    />
                </Accordion.Title>
                <Accordion.Content active={activeNotification === key}>
                    <p>{notification.text}</p>
                </Accordion.Content>
            </React.Fragment>
        );
    };
}

export default NavBarComponent;

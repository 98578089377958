import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Responsive, Loader, Segment, Button, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { GlobalPhrase, PortalPhrase, PhraseHeader, PhraseParagraph } from '../../components';
import { GlobalPhrases } from '../../utility';
import './style.css';

class NotificationsComponent extends React.Component {
    componentDidMount() {
        const { fetchNotifications, notifications, isLoadingNotifications } = this.props;
        document.addEventListener('mousedown', this.handleClick, false);
        if (_.isEmpty(notifications) && !isLoadingNotifications) {
            fetchNotifications();
        }
    }

    componentDidUpdate(prevProps) {
        const { isNotificationsVisible, notifications, updateReadNotifications } = this.props;
        if (!isNotificationsVisible && prevProps.isNotificationsVisible) {
            const readNotifications = _.map(notifications, 'guid');
            if (!_.isEmpty(readNotifications)) {
                updateReadNotifications(readNotifications);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = e => {
        const { isNotificationsVisible, hideNotifications } = this.props;
        if (isNotificationsVisible && !ReactDOM.findDOMNode(this).contains(e.target)) {
            hideNotifications();
        }
    };

    handleToggleNotifications = () => {
        const { isNotificationsVisible, showNotifications, hideNotifications } = this.props;
        if (isNotificationsVisible) {
            hideNotifications();
        } else {
            showNotifications();
        }
    };

    getDateRepresentation = (dateTime) => {
        const updated = moment(dateTime);
        if (!updated.isValid()) {
            //Unrecognized format
            return this.renderTime({ defaultText: dateTime });
        }
        const currentDateTime = moment();
        if (currentDateTime.isBefore(updated))
            return this.renderTime(
                {
                    defaultText: 'NÅ',
                    guid: '7334f871-9f4c-4937-be5f-a8466b531823'
                },
                {}
            );
        if (currentDateTime.isSame(updated, 'minute'))
            return this.renderTime(
                { defaultText: currentDateTime.diff(updated, 'seconds') },
                {
                    defaultText: 'sek',
                    guid: 'adc4d236-361d-46b2-b85b-4564e2e6b6e8'
                }
            );
        if (currentDateTime.isSame(updated, 'hour'))
            return this.renderTime(
                { defaultText: currentDateTime.diff(updated, 'minutes') },
                {
                    defaultText: 'min',
                    guid: 'c0507756-e204-4a5a-9d78-c64b4cde0467'
                }
            );
        if (currentDateTime.isSame(updated, 'day'))
            return this.renderTime(
                { defaultText: currentDateTime.diff(updated, 'hours') },
                {
                    defaultText: 'timer',
                    guid: '22616529-8674-4a87-b808-bb54acd5887a'
                }
            );
        return this.renderTime(
            { defaultText: updated.format('D') },
            { defaultText: updated.format('MMM') }
        );
    }

    render() {
        const { isNotificationsVisible } = this.props;
        return (
            <React.Fragment>
                <Responsive
                    minWidth={Responsive.onlyTablet.minWidth}
                    fireOnMount
                    aria-hidden={!isNotificationsVisible}
                    className={
                        isNotificationsVisible
                            ? 'notifications-container'
                            : 'notifications-container collapse'
                    }
                >
                    {this.renderContent()}
                </Responsive>
                <Responsive
                    maxWidth={Responsive.onlyMobile.maxWidth}
                    fireOnMount
                    aria-hidden={!isNotificationsVisible}
                    className={
                        isNotificationsVisible
                            ? 'notifications-container mobile'
                            : 'notifications-container mobile collapse'
                    }
                >
                    {this.renderContent()}
                </Responsive>
            </React.Fragment>
        );
    }

    renderContent = () => {
        const { notifications, isLoadingNotifications } = this.props;

        return (<React.Fragment>
            {this.renderHeader()}
                    {_.isEmpty(notifications) &&
                !isLoadingNotifications &&
                this.renderNoNotification()}
                    {isLoadingNotifications && (
                <Loader
                    active
                    inline="centered"
                    style={{ marginTop: '1em' }}
                />
            )}
                    {!isLoadingNotifications &&
                _.map(notifications, item => this.renderNotification(item))}
                </React.Fragment>);
    }

    renderHeader = () => {
        const { hideNotifications } = this.props;
        return (
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '4em',
                    backgroundColor: 'var(--surface-color)'
                }}
            >
                <GlobalPhrase
                    defaultText={GlobalPhrases.notifications.default}
                    guid={GlobalPhrases.notifications.guid}
                    hasText
                >
                    <PhraseHeader
                        style={{
                                justifyContent: 'center',
                                marginTop: 'auto',
                                marginBottom: 'auto'
                            }}
                    />
                </GlobalPhrase>
                <Button onClick={hideNotifications} style={{ background: 'none', position: 'absolute', right: '0' }}>
                    <Icon name="close" />
                </Button>
            </span>
        );
    }


    renderNoNotification = () => (
        <PortalPhrase
            defaultText="Ingen varslinger"
            guid="93d9dfc2-1308-4dd9-99f7-f77fe716fdad"
            hasText
        >
            <PhraseParagraph
                style={{
                    textAlign: 'center',
                    marginTop: '1em',
                    fontWeight: 'bold',
                    fontSize: '1.3em',
                    color: 'var(--background-on-color)',
                    opacity: '0.3'
                }}
            />
        </PortalPhrase>
    );

    renderNotification = (item) => {
        return (
            <Segment
                as="div"
                key={item.guid}
                style={{
                    minHeight: '5em',
                    display: 'flex',
                    margin: '1em 0 0',
                    padding: 0,
                    border: 0,
                    borderRadius: '6px',
                    borderTop: '1px',
                    borderBottom: '1px'
                }}
                className="notifcation-selectable"
            >
                <div
                    style={{
                        width: '5em',
                        backgroundColor: 'var(--success-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'var(--success-on-color)',
                        flexDirection: 'column',
                        borderRadius: '6px 0 0 6px'
                    }}
                >
                    {this.getDateRepresentation(item.updated)}
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'var(--material-gray-1)',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'var(--surface-on-color)',
                            padding: '1em',
                            minHeight: '3em',
                            fontWeight: 'bold',
                            height: !item.title || !item.text ? '100%' : 'auto'
                        }}
                    >
                        <p>{item.title ? item.title : item.text}</p>
                    </div>
                    {item.title && (
                        <div
                            style={{
                                padding: '1em',
                                background: 'var(--background-color)',
                                color: 'var(--background-on-color)',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {item.text}
                        </div>
                    )}
                </div>
            </Segment>
        );
    }

    renderTime = (text1, { defaultText, guid }) => {
        return (
            <React.Fragment>
                <PortalPhrase
                    defaultText={text1.defaultText}
                    guid={text1.guid}
                    hasText
                >
                    <PhraseParagraph
                        style={{
                        fontWeight: 'bold',
                        margin: '0',
                        fontSize: '2em',
                        lineHeight: '1'
                    }}
                    />
                </PortalPhrase>
                <PortalPhrase defaultText={defaultText} guid={guid} hasText>
                    <PhraseParagraph
                        style={{
                        margin: '0',
                        fontSize: '1.1em',
                        lineHeight: '1'
                    }}
                    />
                </PortalPhrase>
            </React.Fragment>
        );
    }
}

export default NotificationsComponent;